(function (global) {
  /**
   * @class EDRUtility
   * A collection of useful utility methods which would normally be provided by a framework.
   */
  var EDRUtility = {
    /**
     * Helper function that implements a (pseudo)classical inheritance model.
     * @see http://www.yuiblog.com/blog/2010/01/06/inheritance-patterns-in-yui-3/
     * @param {Function} childClass
     * @param {Function} parentClass
     */
    inherits: function (childClass, parentClass) {
      /** @constructor */
      var tempClass = function () {};
      tempClass.prototype = parentClass.prototype;
      childClass.prototype = new tempClass();
      childClass.superclass = parentClass.prototype;
      childClass.prototype.constructor = childClass;
    },

    /**
     * Sets the style attribute of the specified element to the provided value.
     * @param {Element}  e    The element on which to set the style.
     * @param {String}   css  The css style string to set.
     */
    setStyleAttribute: function (e, css) {
      if (e.style.setAttribute) {
        // for IE
        e.style.setAttribute('cssText', css);
      } else {
        e.setAttribute('style', css);
      }
    },

    /**
     * Sets the content of the specified css style block to the provided value.
     * @param {Element}  block  The css style block.
     * @param {String}   css    The css string to set.
     */
    setStyleBlock: function (block, css) {
      if (block.styleSheet) {
        block.styleSheet.cssText = css;
      } else {
        if (block.hasChildNodes()) {
          while (block.childNodes.length >= 1) {
            block.removeChild(block.firstChild);
          }
        }
        block.appendChild(document.createTextNode(css));
      }
    },

    /**
     * Returns the named css style value for an element.
     * @param {Element}  el     The element.
     * @param {String}   style  The style property to return the value of.
     * @return {String}
     */
    getStyle: function (el, style) {
      var y;
      if (el.currentStyle) {
        y = el.currentStyle[style];
      } else if (window.getComputedStyle) {
        y = document.defaultView
          .getComputedStyle(el, null)
          .getPropertyValue(style);
      }
      return y;
    },

    /**
     * Cross-browser implementation of attachEvent.
     * @param {Element}  obj        The target.
     * @param {String}   type       The event name.
     * @param {Function} fn         The event handler function.
     * @param {Boolean=} useCapture Whether to use capture.
     */
    addEvent: function (obj, type, fn, useCapture) {
      if (obj.addEventListener) {
        obj.addEventListener(type, fn, useCapture || false);
      } else if (obj.attachEvent) {
        obj['e' + type + fn] = fn;
        obj[type + fn] = function () {
          var e = window.event;
          var o = { target: e.srcElement, type: e.type, keyCode: e.keyCode };
          obj['e' + type + fn](o);
        };
        obj.attachEvent('on' + type, obj[type + fn]);
      } else {
        obj['on' + type] = obj['e' + type + fn];
      }
    },

    /**
     * Shortcut method to add multiple events to one object.
     * @param {Element}   obj    The target.
     * @param {String}    types  The event names.
     * @param {Function}  fn     The event handler function for all events.
     * @param {Boolean=} useCapture Whether to use capture.
     */
    addEvents: function (obj, types, fn, useCapture) {
      for (var i = 0; i < types.length; ++i) {
        this.addEvent(obj, types[i], fn, useCapture);
      }
    },

    /**
     * Returns true if the provided DOM element matches the provided css selector.
     * @param {Element}  e         The element to test.
     * @param {String}   selector  The css selector to match.
     * @return {Boolean}
     */
    matchesSelector: function (e, selector) {
      var eles = document.querySelectorAll(selector);
      for (var i = 0; i < eles.length; ++i) {
        if (eles[i] === e) {
          return true;
        }
      }

      return false;
    },

    /**
     * Returns the fixed position for any element.
     * @param {Element}  e  The element to find the fixed position of.
     * @return {Object.<number,number>}
     */
    findFixedPos: function (e) {
      var rect = e.getBoundingClientRect();
      return { x: rect.left, y: rect.top };
    },

    /**
     * Returns the view port size.
     * @return {Object.<number, number>}
     */
    getViewportSize: function () {
      var w = 0,
        h = 0;

      if (!window.innerWidth) {
        // IE
        if (document.documentElement.clientWidth !== 0) {
          // strict mode
          w = document.documentElement.clientWidth;
          h = document.documentElement.clientHeight;
        } else {
          // quirks mode
          w = document.body.clientWidth;
          h = document.body.clientHeight;
        }
      } else {
        // w3c
        w = window.innerWidth;
        h = window.innerHeight;
      }

      return { x: w, y: h };
    },

    /**
     * Create a cookie with supplied name and value which will last for the specified number of days.
     * @param {String}  name    The cookie name.
     * @param {String}  value   The cookie value/
     * @param {Number}  days    (optional) The number of days this cookie will last. Default = session cookie.
     * @param {String}  domain  (optional) The domain path.
     */
    createCookie: function (name, value, days, domain) {
      var expires;
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toGMTString();
      } else {
        expires = '';
      }

      var v = name + '=' + value + expires + '; path=/';
      if (domain) {
        v += ';domain=' + domain;
      }
      document.cookie = v;
    },

    /**
     * Return the value of a cookie with the supplied name.
     * @param {String}  name         The name of the cookie to read.
     * @param {String}  [delimiter]  If set, will return all cookies with this name on all domains, separated with this string.
     * @return {String}
     */
    readCookie: function (name, delimiter) {
      var nameEQ = name + '=';
      var ca = document.cookie.split(';');
      var ret = [];
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          ret.push(c.substring(nameEQ.length, c.length));
        }
      }

      if (ret.length === 0) {
        return null;
      }

      return delimiter ? ret.join(delimiter) : ret[0];
    },

    /**
     * Return all the cookies with the specified prefix.
     * @param prefix
     * @returns {{}}
     */
    readCookiesWithPrefix: function (prefix) {
      var ca = document.cookie.split(';');
      var ret = {};
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        var pair = c.split('=');
        if (0 === pair[0].indexOf(prefix)) {
          ret[pair[0]] = pair[1];
        }
      }
      return ret;
    },

    /**
     * Remove a cookie with the specified name.
     * @param {String}  name    The name of the cookie to delete.
     * @param {String}  domain  Must have the same domain specified when erasing.
     */
    eraseCookie: function (name, domain) {
      this.createCookie(name, '', -1, domain);
    },

    /**
     * Returns true if the browser environment supports cookies, and they're switched on.
     * @return {Boolean}
     */
    areCookiesEnabled: function () {
      var r = false;
      var n = 'edr_sc_tst';
      this.createCookie(n, 'tst_val', 1);
      if (this.readCookie(n) !== null) {
        r = true;
        this.eraseCookie(n);
      }
      return r;
    },

    /**
     * Return the version of Adobe Flash which is installed, dot-separated. Returns NULL if Flash is not detected.
     * @return {?String}
     */
    getFlashVersion: function () {
      var ver = null;

      function parseVersion(d) {
        d = d.match(/[\d]+/g);
        d.length = 3;
        return d.join('.');
      }

      if (navigator.plugins && navigator.plugins.length) {
        var e = navigator.plugins['Shockwave Flash'];
        if (e && e.description) {
          ver = parseVersion(e.description);
        } else if (navigator.plugins['Shockwave Flash 2.0']) {
          ver = '2.0.0.11';
        }
      } else {
        if (navigator.mimeTypes && navigator.mimeTypes.length) {
          var f = navigator.mimeTypes['application/x-shockwave-flash'];
          if (f && f.enabledPlugin) {
            ver = parseVersion(f.enabledPlugin.description);
          }
        } else {
          var flashObj;
          try {
            flashObj = new ActiveXObject('ShockwaveFlash.ShockwaveFlash.7');
            ver = parseVersion(flashObj.GetVariable('$version'));
          } catch (h) {
            try {
              flashObj = new ActiveXObject('ShockwaveFlash.ShockwaveFlash.6');
              ver = '6.0.21';
            } catch (i) {
              try {
                flashObj = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
                ver = parseVersion(flashObj.GetVariable('$version'));
              } catch (j) {}
            }
          }
        }
      }

      return ver ? ver : null;
    },

    /**
     * Clone a javascript object.
     * @see http://stackoverflow.com/questions/728360/copying-an-object-in-javascript
     * @param {Object}  obj  The object to clone.
     * @return {Object}
     */
    clone: function (obj) {
      if (null === obj || 'object' !== typeof obj) {
        return obj;
      }
      var copy = obj.constructor();
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.clone(obj[attr]);
        }
      }
      return copy;
    },

    _ifrRequestCount: 0,

    /**
     * Posts x-domain.
     *
     * Works by creating an iframe and a form with all the given post parameters. The form targets the iframe and
     * submits to it. When the post has completed any attempt to access 'iframe.contentDocument' will throw an
     * exception because it now has x-domain content. We catch this exception in-order to know it has completed.
     *
     * You will need to use another (simpler) method if you want to post on the same domain as this method will
     * not know when it has finished (no exception will be fired).
     */
    postToUrl: function (
      path,
      params,
      documentDomainExplicitlySet,
      onComplete,
      method
    ) {
      var id = 'iframe_req_' + ++this._ifrRequestCount;

      var iframe = document.createElement('iframe');

      var requiresDocumentDomainHack =
        documentDomainExplicitlySet &&
        navigator.appName === 'Microsoft Internet Explorer';

      if (requiresDocumentDomainHack) {
        iframe.src =
          "javascript:void((function(){document.open();document.domain='" +
          document.domain +
          "';document.close();})())";
      }

      iframe.setAttribute('id', id);
      iframe.setAttribute('name', id);
      iframe.setAttribute('tabindex', -1);
      this.setStyleAttribute(
        iframe,
        'position:absolute;left:-100000px;top:-100000px;'
      );

      document.body.appendChild(iframe);

      // This helps most IE versions regardless of the creation method
      if (window.frames && window.frames[id]) {
        iframe = window.frames[id];
      }
      iframe.name = id;

      var doPost = function () {
        var ifrDoc = iframe.contentDocument || iframe.document;

        method = method || 'post'; // Set method to post by default, if not specified.

        var form = document.createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);

        for (var key in params) {
          if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);

            var val = params[key];
            val = typeof val === 'object' ? JSON.stringify(val) : val;
            hiddenField.setAttribute('value', val);

            form.appendChild(hiddenField);
          }
        }

        form.setAttribute('target', id);

        document.body.appendChild(form);

        form.submit();

        var check = function () {
          // check if iframe has loaded
          var doc = null;
          try {
            doc = iframe.contentDocument || iframe.document;
          } catch (e) {
            // We expect to get a 'SecurityError' here when the iframe has posted successfully because the
            // iframe now contains x-domain content and we tried to access it.
            // Now doc == null.
          }

          // This is supposed to be if(doc) and NOT if(!doc). See method comment.
          if (doc) {
            setTimeout(check, 10);
          } else {
            document.body.removeChild(form);
            document.body.removeChild(document.getElementById(id));
            if (onComplete) {
              onComplete();
            }
          }
        };

        setTimeout(check, 10);
      };

      if (requiresDocumentDomainHack) {
        // requires a delay to allow the hack to take affect
        setTimeout(doPost, 1);
      } else {
        doPost();
      }
    },

    genGuid: function () {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return (
        S4() +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        '-' +
        S4() +
        S4() +
        S4()
      );
    },

    /** Provide an implementation for Array.indexOf for those browsers that don't support Array.indexOf (< IE9) */
    arrayIndexOf: function (arr, searchElement /*, fromIndex */) {
      'use strict';
      if (Array.prototype.indexOf) {
        return arr.indexOf(searchElement);
      }
      if (arr === null) {
        throw new TypeError();
      }
      var t = Object(arr);
      var len = t.length >>> 0;
      if (len === 0) {
        return -1;
      }
      var n = 0;
      if (arguments.length > 2) {
        n = Number(arguments[2]);
        if (n != n) {
          // shortcut for verifying if it's NaN
          n = 0;
        } else if (n !== 0 && n !== Infinity && n !== -Infinity) {
          n = (n > 0 || -1) * Math.floor(Math.abs(n));
        }
      }
      if (n >= len) {
        return -1;
      }
      var k = n >= 0 ? n : Math.max(len - Math.abs(n), 0);
      for (; k < len; k++) {
        if (k in t && t[k] === searchElement) {
          return k;
        }
      }
      return -1;
    },

    isIEOrEdge: function () {
      return !!(
        (document.all && document.querySelector) || // IE8 - IE10
        ('-ms-scroll-limit' in document.documentElement.style &&
          '-ms-ime-align' in document.documentElement.style) || // IE11
        /Edge\/\d+./i.test(navigator.userAgent)
      ); // Edge
    },

    isAndroid: function () {
      return navigator.userAgent.toLowerCase().indexOf('android') > -1;
    },

    isIOS: function () {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },

    iOSVersion: function () {
      if (!this.isIOS()) {
        return undefined;
      }
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    },

    /**
     * Whether the current device will continue to execute javascript on inactive tabs/windows.
     *
     * @returns {boolean}
     */
    executesInactiveTabs: function () {
      var ios = this.iOSVersion();

      if (!ios) {
        // assume all non-ios devices execute inactive tabs, empirical testing supports this
        return true;
      }

      return ios[0] >= 8; // Safari Mobile for iOS < 8 does not execute Javascript on inactive tabs
    },

    getVisibilityChangeEventNames: function () {
      var hidden, visibilityChange;
      if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
      } else if (typeof document.mozHidden !== 'undefined') {
        hidden = 'mozHidden';
        visibilityChange = 'mozvisibilitychange';
      } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
      } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
      }
      return {
        hidden: hidden,
        visibilitychange: visibilityChange,
      };
    },
  };

  // export the symbols that we want to be available externally, free from obfuscation by the closure compiler
  // this is essentially our public interface
  global['EDRUtility'] = EDRUtility;
})(window);
(function (window) {
  var global = this,
    EDRUtility = window['EDRUtility'];

  /**
   * Holds all of the Maru/edr survey code probe functionality.
   */
  var EDRSurveyCodeProbes = {};

  /**
   * Create a set of probes from the provided configuration.
   *
   * @param {Object.<string, object>}  config  The probe configuration.
   * @returns {Object.<string, object>}
   */
  EDRSurveyCodeProbes.createFromConfig = function (config) {
    var ret = {};

    if (config) {
      // construct each probe object and execute each probe
      for (var id in config) {
        if (config.hasOwnProperty(id)) {
          var probe = EDRSurveyCodeProbes.Probe.create(id, config[id]);
          if (probe) {
            ret[id] = probe;
          }
        }
      }
    }

    return ret;
  };

  /**
   * Execute the provided probes on the given window object.
   *
   * @param {Object}                                      win           The window in which to probe.
   * @param {Object.<string, EDRSurveyCodeProbes.Probe>}  probes        A list of created probe objects
   * @param {Object.<string, object>}                     pushedValues  Any "pushed" values.
   * @returns {Object.<string, object>}
   */
  EDRSurveyCodeProbes.execute = function (win, probes, pushedValues) {
    var ret = {};

    // construct each probe object and execute each probe
    for (var id in probes) {
      if (probes.hasOwnProperty(id)) {
        var probe = probes[id];

        // shove the any pushed probe value into the correct push probe before we get it's value
        if (probe.config['kind'] == 'push') {
          var ppv = pushedValues[probe.config['code']];
          probe.pushedValue = ppv !== undefined ? ppv : null;
        }
        ret[id] = probe.getValue(win);
      }
    }

    return ret;
  };

  /**
   * Abstract base class which looks after the state of a layer probe.
   *
   * @param config The configuration of this probe.
   * @constructor
   */
  EDRSurveyCodeProbes.Probe = function (id, config) {
    /**
     * The probe configuration.
     * @type {Object}
     * @public
     */
    this.config = config;

    /**
     * The value "pushed" to this probe by external script. Only relevant for "push" probe types.
     * @type {*}
     * @public
     */
    this.pushedValue = null;
  };

  /**
   * Create a new probe from probe deployment configuration.
   *
   * @static
   * @param {Object} config The probe configuration.
   * @return {EDRSurveyCodeProbes.Probe}
   */
  EDRSurveyCodeProbes.Probe.create = function (id, config) {
    switch (config['kind']) {
      case 'resultHistory':
        return new EDRSurveyCodeProbes.Probe.ResultHistory(id, config);

      case 'cookieValue':
      case 'elementContent':
      case 'pageUrl':
        return new EDRSurveyCodeProbes.Probe.String(id, config);
      case 'cookieExists':
      case 'elementExists':
      case 'elementVisible':
        return new EDRSurveyCodeProbes.Probe.Boolean(id, config);
      case 'elementCount':
        return new EDRSurveyCodeProbes.Probe.Number(id, config);

      case 'push':
        switch (config['type']) {
          case 'bool':
            return new EDRSurveyCodeProbes.Probe.Boolean(id, config);
          case 'string':
            return new EDRSurveyCodeProbes.Probe.String(id, config);
          case 'number':
            return new EDRSurveyCodeProbes.Probe.Number(id, config);
          default:
            return null;
        }
        break;

      default:
        return null;
    }
  };

  /**
   * Base class for all probe values (array) types.
   *
   * @param {Object}  config  The probe configuration.
   * @constructor
   * @extends {EDRSurveyCodeProbes.Probe}
   */
  EDRSurveyCodeProbes.Probe.String = function (id, config) {
    try {
      this._regEx = config['regex'] ? new RegExp(config['regex']) : null;
    } catch (e) {}

    /**
     * If true, will return an array of collected values rather than a single string.
     * @type {boolean}
     * @private
     */
    this._returnAll = false;

    /**
     * The index into the values array which will become the actual value.
     * @type {Number}
     * @private
     */
    this._returnIndex = 0;

    /**
     * Relevant only to ElementContent kinds, when true this probe will only detect and return element content
     * from visible elements.
     * @type {boolean}
     * @private
     */
    this._visibleOnly = config['visibleOnly'] === true;

    if (config['returnAll']) {
      this._returnAll = true;
    } else {
      this._returnIndex = config['index'] ? config['index'] : 0;
    }

    EDRSurveyCodeProbes.Probe.call(this, id, config);
  };
  EDRUtility.inherits(
    EDRSurveyCodeProbes.Probe.String,
    EDRSurveyCodeProbes.Probe
  );

  /**
   * Returns the array value got from executing this probe.
   * @return {(String|Array)}
   */
  EDRSurveyCodeProbes.Probe.String.prototype.getValue = function (win) {
    var collection = [];

    switch (this.config['kind']) {
      case 'cookieValue':
        var v = EDRUtility.readCookie(this.config['cookieName']);
        if (v) {
          collection.push(v);
        }
        break;

      case 'elementContent':
        var es = win.document.querySelectorAll(this.config['selector']);
        for (var i = 0; i < es.length; ++i) {
          if (this._visibleOnly && es[i].offsetWidth === 0) {
            continue;
          }
          var content = es[i].textContent || es[i].innerText;
          collection.push(content);
        }
        break;

      case 'pageUrl':
        collection.push(win.document.location.href);
        break;

      case 'push':
        collection.push(this.pushedValue);
        break;
    }

    if (this._regEx) {
      var transformed = [];

      for (var o = 0; o < collection.length; ++o) {
        // pass through the regex
        var result = this._regEx.exec(collection[o]);

        if (result && result.length === 1) {
          // no capture groups - use whole value
          transformed.push(collection[o]);
        } else if (result) {
          // capture groups - append each captured group together
          for (var u = 1; u < result.length; ++u) {
            transformed.push(result[u]);
          }
        }
      }

      collection = transformed;
    }

    if (this._returnAll) {
      return collection;
    }

    var index = this._returnIndex;
    return index < 0
      ? null
      : index >= collection.length
      ? null
      : collection[index];
  };

  /**
   * Base class for all probe boolean types.
   *
   * @param {Object}  config  The probe configuration.
   * @constructor
   * @extends {EDRSurveyCodeProbes.Probe}
   */
  EDRSurveyCodeProbes.Probe.Boolean = function (id, config) {
    EDRSurveyCodeProbes.Probe.call(this, id, config);
  };
  EDRUtility.inherits(
    EDRSurveyCodeProbes.Probe.Boolean,
    EDRSurveyCodeProbes.Probe
  );

  /**
   * Returns the boolean value from executing this probe.
   * @return {boolean}
   */
  EDRSurveyCodeProbes.Probe.Boolean.prototype.getValue = function (win) {
    var val = false;

    switch (this.config['kind']) {
      case 'cookieExists':
        val = EDRUtility.readCookie(this.config['cookieName']) !== null;
        break;
      case 'elementExists':
        val = win.document.querySelector(this.config['selector']) !== null;
        break;
      case 'elementVisible':
        var es = win.document.querySelectorAll(this.config['selector']);
        for (var i = 0; i < es.length; ++i) {
          if (es[i].offsetWidth !== 0) {
            val = true;
            break;
          }
        }
        break;

      case 'push':
        val = this.pushedValue;
        break;
    }

    if (this.config['not']) {
      val = !val;
    }

    return val;
  };

  /**
   * Base class for all probe count types.
   *
   * @param {Object}  config  The probe configuration.
   * @constructor
   * @extends {EDRSurveyCodeProbes.Probe}
   */
  EDRSurveyCodeProbes.Probe.Number = function (id, config) {
    EDRSurveyCodeProbes.Probe.call(this, id, config);
  };
  EDRUtility.inherits(
    EDRSurveyCodeProbes.Probe.Number,
    EDRSurveyCodeProbes.Probe
  );

  /**
   * Returns the integer value from executing this probe.
   * @return {Number}
   */
  EDRSurveyCodeProbes.Probe.Number.prototype.getValue = function (win) {
    switch (this.config['kind']) {
      case 'elementCount':
        return win.document.querySelectorAll(this.config['selector']).length;

      case 'push':
        return this.pushedValue;
    }
  };

  /**
   * Collect the results from a different probe type into a growing list of results.
   *
   * Useful for holding pages to track history while the holding page is showing.
   *
   * @param {Object}  config  The probe configuration.
   * @constructor
   * @extends {EDRSurveyCodeProbes.Probe}
   */
  EDRSurveyCodeProbes.Probe.ResultHistory = function (id, config) {
    /**
     * True if the probe should allow duplicate values in it's collected results, false otherwise.
     * @type {boolean}
     * @private
     */
    this._allowDuplicates = config['allowDuplicates'] === true;

    /**
     * An instance of another probe type which will be used to do the actual "probing".
     * @type {EDRSurveyCodeProbes.Probe}
     * @private
     */
    this._innerProbe = EDRSurveyCodeProbes.Probe.create(
      id + '_inner',
      config['innerProbe']
    );

    /**
     * Member to track the last result from the inner probe. As probes can be executed over and over again,
     * this is used to make sure that we only add results to the collection when the results change.
     * @type {*}
     * @private
     */
    this._lastResult = null;

    /**
     * The collection of results.
     * @type {Array.<*>}
     * @private
     */
    this._collection = [];

    EDRSurveyCodeProbes.Probe.call(this, id, config);
  };
  EDRUtility.inherits(
    EDRSurveyCodeProbes.Probe.ResultHistory,
    EDRSurveyCodeProbes.Probe
  );

  /**
   * Executes the inner probe, adds it's result to the collection (if necessary) and returns the collection.
   * @return {Array.<*>}
   */
  EDRSurveyCodeProbes.Probe.ResultHistory.prototype.getValue = function (win) {
    // execute the inner probe
    var result = this._innerProbe.getValue(win);

    if (JSON.stringify(result) === JSON.stringify(this._lastResult)) {
      // result hasn't changed - return existing collection
      return this._collection;
    }

    this._lastResult = result;

    var isArray = Object.prototype.toString.call(result) === '[object Array]';
    result = isArray ? result : [result];

    for (var i = 0; i < result.length; ++i) {
      if (!this._allowDuplicates) {
        // scan for duplicates
        var exists = false;
        for (var u = 0; u < this._collection.length; ++u) {
          if (result[i] === this._collection[u]['val']) {
            exists = true;
            break;
          }
        }

        if (exists) {
          continue;
        }
      }

      this._collection.push({
        dt: Math.round(new Date().getTime() / 1000),
        val: result[i],
      });
    }

    return this._collection;
  };

  // export the symbols that we want to be available externally, free from obfuscation by the closure compiler
  // this is essentially our public interface
  global['EDRSurveyCodeProbes'] = EDRSurveyCodeProbes;
})(window);
/**
 * Lightweight, host-side XDM library which uses window.postMessage() and therefore doesn't support IE7 or earlier.
 * This RPC interface is compatible with that of easyXDM.
 * Copyright (C) 2012 Maru/edr.
 * http://www.maruedr.com
 */
(function (window, document, setTimeout, encodeURIComponent) {
  var global = this;

  var eDRXDMClient = {
    _lastChannelId: 0,

    Rpc: function (config, rpcInterface) {
      // expand shorthand notation
      if (rpcInterface['local']) {
        for (var method in rpcInterface['local']) {
          if (rpcInterface['local'].hasOwnProperty(method)) {
            var member = rpcInterface['local'][method];
            if (typeof member === 'function') {
              rpcInterface['local'][method] = {
                method: member,
              };
            }
          }
        }
      }

      var channelId = 'edr' + eDRXDMClient._lastChannelId++;

      // create the IFRAME
      var frame = document.createElement('iframe');
      frame.id = frame.name = config['props']['id'];
      frame.border = frame.frameBorder = 0;
      frame['allowTransparency'] = true;
      frame.src =
        config['remote'] +
        '&xdm_o=' +
        encodeURIComponent(
          eDRXDMClient._getLocation(window.location.toString())
        ) +
        '&xdm_c=' +
        channelId;
      document.getElementById(config['container']).appendChild(frame);

      return new eDRXDMClient.RpcBehaviour(
        config,
        rpcInterface,
        frame,
        eDRXDMClient._getLocation(config['remote']),
        channelId
      );
    },

    _getLocation: function (url) {
      var reURI = /^((http.?:)\/\/([^:\/\s]+)(:\d+)*)/; // returns groups for protocol (2), domain (3) and port (4)
      var m = url.toLowerCase().match(reURI);
      var proto = m[2],
        domain = m[3],
        port = m[4] || '';
      if (
        (proto === 'http:' && port === ':80') ||
        (proto === 'https:' && port === ':443')
      ) {
        port = '';
      }
      return proto + '//' + domain + port;
    },
  };

  eDRXDMClient.RpcBehaviour = function (
    config,
    rpcInterface,
    frame,
    remoteDomain,
    channelId
  ) {
    /**
     * Flag to indicate whether the channel is "ready".
     * @type {boolean}
     * @private
     */
    var _ready = false;

    function _createMethod(definition, method) {
      var slice = Array.prototype.slice;

      return function () {
        var message = {
          method: method,
          params: slice.call(arguments, 0),
        };

        // Send the method request
        frame.contentWindow.postMessage(JSON.stringify(message), remoteDomain);
      };
    }

    function _executeMethod(method, fn, params) {
      var result = fn.method.apply(fn.scope, params);
    }

    // implement the remote side's defined methods
    for (var method in rpcInterface['remote']) {
      if (rpcInterface['remote'].hasOwnProperty(method)) {
        this[method] = _createMethod(rpcInterface['remote'][method], method);
      }
    }

    function _onMessage(e) {
      // security - check that this message came from the expected origin
      if (e.origin !== remoteDomain) {
        return;
      }

      var data;

      try {
        data = JSON.parse(e.data);
      } catch (e) {
        // stop invalid JSON from producing an error which bubbles up to the browser's root context
        // discard this message
        return;
      }

      if (data['channel'] !== channelId) {
        // message not for this channel
        return;
      }

      if (data['internal']) {
        if (!_ready) {
          if (data['internal'] === '-ready-') {
            setTimeout(config['onReady'], 0);
            _ready = true;
          }
        }
        return;
      }

      _executeMethod(
        data['method'],
        rpcInterface['local'][data['method']],
        data['params']
      );
    }

    // add an event listener for processing received messages
    if (window.addEventListener) {
      window.addEventListener('message', _onMessage, false);
    } else {
      window.attachEvent('onmessage', _onMessage);
    }
  };

  global['eDRXDMClient'] = eDRXDMClient;
  global['eDRXDMClient'].Rpc = eDRXDMClient.Rpc;
})(window, document, window.setTimeout, encodeURIComponent);
/**
 * @preserve Maru/edr survey code v7.2.9.1
 *   Copyright (C) 2012 Maru/edr all rights reserved.
 *   http://www.maruedr.com
 */

(function (
  window,
  document,
  location,
  setTimeout,
  clearTimeout,
  setInterval,
  clearInterval,
  decodeURIComponent,
  encodeURIComponent,
  navigatorUserAgent
) {
  var global = this,
    EDRUtility = window['EDRUtility'],
    EDRSurveyCodeProbes = window['EDRSurveyCodeProbes'];

  // Is this a duplicate installation of the survey code?
  if (typeof global['EDRSurvey'] != 'undefined') {
    global['EDRSurvey'].getTester().log(33, null, null, 4);
    return;
  }

  /**
   * Holds all of the eDigitalResearch survey code functionality.
   */
  var EDRSurveyCode = {};

  EDRSurveyCode.TRACKING_COOKIE_NAME = 'eds_uuid';
  EDRSurveyCode.TRACKING_DAYS_DISABLED = -1;

  /**
   * @class EDRSurveyCode.PageState
   * Static class which holds the global page state.
   * @type {Object}
   */
  EDRSurveyCode.PageState = {
    /**
     * The number of times the user has viewed a page with this master code installed.
     * @public
     * @type {int}
     */
    pageViewCount: 0,

    /**
     * True if the hosting webpage explicitly sets document.domain.
     * This information is needed to make sure that holding pages work properly under IE.
     * @public
     * @type {Boolean}
     */
    documentDomainExplicitlySet: false,

    /**
     * The date/time stamp the page was loaded.
     * @private
     * @type {Date}
     */
    _timeLoaded: null,

    init: function () {
      this._timeLoaded = new Date();

      // determine if document.domain has been explicitly set

      // detecting for domain change is easy...
      this.documentDomainExplicitlySet =
        document.location.hostname !== document.domain;

      if (!this.documentDomainExplicitlySet) {
        // ... but that doesn't cover the case where it's been explicitly set to it's original value!
        // We can detect for this by creating an IFRAME and seeing whether we can access it's content
        var tmpIframe = document.createElement('iframe');
        document.body.appendChild(tmpIframe);
        try {
          var t = tmpIframe.contentWindow.document;
        } catch (e) {
          this.documentDomainExplicitlySet = true;
        }
        tmpIframe.parentNode.removeChild(tmpIframe);
      }
    },

    /**
     * Gets the number of milliseconds since the page was loaded.
     * @type {int}
     */
    getMillisecondsSincePageLoad: function () {
      var now = new Date();
      return now - this._timeLoaded;
    },
  };

  /**
   * A model of an eDigitalResearch layer which is displayed inside an IFRAME and hosted on eDR servers.
   * @constructor
   *
   * @param {String}  id                 The deployment id.
   * @param {String}  rootDeploymentId   The root deployment id e.g. xxxxx|yyyyy|zzzzz (deploymentId|deployId|triggerpointId)
   * @param {String}  rootLanguage       The root language to use with the current layer.
   */
  EDRSurveyCode.Layer = function (id, rootDeploymentId, rootLanguage) {
    id = id ? id : null;

    // CONSTANTS

    /**
     * The maximum amount of time (in milliseconds) a layer will wait for the provider to call it back with
     * configuration before giving up and destroying the layer.
     * @const
     * @type {int}
     */
    this.MAX_WAIT_FOR_CONFIGURE = 30000;

    /**
     * The maximum amount of time (in milliseconds) we wait between a detected response from l.php and the layer's
     * javascript calling us back with its configuration. If this times out then the server side must have decided
     * not to display a layer (initially was 500 milliseconds, increased to 1250 milliseconds because of possible
     * browser's slowness).
     * @const
     * @type {int}
     */
    this.MAX_WAIT_FOR_LOAD = 1250;

    /**
     * The maximum amount of time we will wait before hiding the layer after it has been requested.
     * This time is given to the layer to it can animate it's closure, if desired.
     * @type {Number}
     */
    this.MAX_HIDE_TIME = 2000;

    /**
     * This style will effectively hide the layer, but still allow it to be queried for size.
     * @const
     * @type {String}
     */
    this.HIDDEN_STYLE =
      'position:absolute;left:-100000px;top:-100000px;width:100%;height:100%;';

    // PUBLIC MEMBERS
    // ---------------

    /**
     * The ID of the layer. This will be NULL for the initial (first) layer.
     * @type {String}
     */
    this.id = id;

    /**
     * The ID used for logging.
     * @type {String}
     */
    this.logId = id ? id : 'initial';

    /**
     * The layer "name" - not known until configure() has been called so it's not used to index anything.
     * It is used as part of the name for any popup windows that this code opens, however.
     * @type {String}
     * @public
     */
    this.name = null;

    /**
     * The layer's wrapper div dom element.
     * @type {Element}
     * @public
     */
    this.div = null;

    /**
     * The layer's IFRAME dom element.
     * @type {Element}
     * @public
     */
    this.iframe = null;

    /**
     * True if the layer is showing, false otherwise.
     * @type {Boolean}
     * @public
     */
    this.showing = false;

    /**
     * True if this layer has shown at least once (includes persisted layers), false otherwise.
     * @type {Boolean}
     * @public
     */
    this.everShown = false;

    /**
     * True if the layer has shown on the page at least once, false otherwise.
     * @type {boolean}
     * @public
     */
    this.everShownOnPage = false;

    // PRIVATE MEMBERS
    // ---------------

    /**
     * Since a layer can have any id we need to store the deployment id the layer is actually using.
     *
     * This should always get initiated once configured from the servers surveycode.
     *
     * @type {integer}
     * @private
     */
    this._deploymentId = null;

    /**
     * The root deployment id.
     *
     * @type {String}
     * @private
     */
    this._rootDeploymentId = rootDeploymentId;

    /**
     * The language we have to use to display the current layer (and following layers).
     *
     * @type {String}
     * @private
     */
    this._rootLanguage = rootLanguage;

    /**
     * The RPC interface object to the layer content.
     * @type {Object}
     * @private
     */
    this._rpc = null;

    /**
     * The layer's STYLE dom element.
     * @type {Element}
     * @private
     */
    this._styleBlock = null;

    /**
     * The layer's wrapper div id attribute value.
     * @type {String}
     * @private
     */
    this._divId = 'edr_lwrap_' + (id ? id : 'first');

    /**
     * The layer's IFRAME id attribute value.
     * @type {String}
     * @private
     */
    this._iframeId = 'edr_l_' + (id ? id : 'first');

    /**
     * The styles that the server says should be applied to the IFRAME.
     * @type {Object.<string, string>}
     * @private
     */
    this._iframeStyles = null;

    /**
     * An instance to a class which is used to "anchor" the layer to a position relative to a DOM element.
     * @type {EDRSurveyCode.PositionedLayer}
     * @private
     */
    this._positioner = null;

    /**
     * The layout configuration for this layer.
     * @type {Object}
     * @private
     */
    this._layout = null;

    /**
     * What to do when the layer is "executed".
     * @type {Object}
     * @private
     */
    this._deploy = {};

    /**
     * The setTimeout() id used to call _tick over and over.
     * @type {Number}
     * @private
     */
    this._ticker = null;

    /**
     * True if initialised() has been called, false otherwise.
     * @type {boolean}
     * @private
     */
    this._initialised = false;

    /**
     * True if configure() has been called, false otherwise.
     * @type {Boolean}
     * @private
     */
    this._configured = false;

    /**
     * The DOMElement which caused this layer to show.
     * @type {Element}
     * @private
     */
    this._triggeringElement = null;

    /**
     * A set of global deployment conditional prerequisites for this layer.
     * @type {Array.<EDRSurveyCode.Conditional>}
     * @private
     */
    this._globalConditionals = [];

    /**
     * Contains the triggered state of each trigger which can show this layer.
     * @type {Array.<EDRSurveyCode.Trigger>}
     * @private
     */
    this._triggers = [];

    /**
     * A variable to hold the setTimeout() return for the timer which will destroy the layer if it's not configured
     * soon enough.
     * @type {Number}
     * @private
     */
    this._configureTimeoutTimer = null;

    /**
     * A variable to hold the setTimeout() return for the timer which will check for the layer to be ready (or not).
     * @type {Number}
     * @private
     */
    this._iframeCheckTimeoutTimer = null;

    /**
     * This member will be set while a layer has been force-hidden due to a global conditional suddenly failing
     * *while* the layer was showing. It's used to reshow the layer automatically when conditionals pass again.
     * When set, it will be "true" if there is no trigger which caused the element to show, else it'll be set to the
     * triggering element.
     * @type {(Boolean|Element)}
     * @private
     */
    this._globalConditionalsHidShowingLayerTrigger = null;

    /**
     * Set to true while we wait for the layer to hide. We only wait for MAX_HIDE_TIME milliseconds for this to happen.
     * @type {Boolean}
     * @private
     */
    this._hiding = false;

    /**
     * Our lightbox DOM element - can be NULL if this layer doesn't use a lightbox.
     * @type {Element}
     * @private
     */
    this._lightboxElement = null;

    /**
     * The id of the interval timer which is used for animating the lightbox.
     * @type {Number}
     * @private
     */
    this._lightboxAnimationStepper = null;

    /**
     * The probe configuration - can be used to construct a load of EDRSurveyCodeProbes.Probe objects.
     * @type {Object.<string, object>}
     * @private
     */
    this._probeConfiguration = {};

    /**
     * A handle to the popup window opened by this layer.
     * @type {Window}
     * @private
     */
    this._surveyWin = null;

    /**
     * This member exists to simply stop us logging the same thing over and over again when it hasn't changed.
     * @type {boolean}
     * @private
     */
    this._loggedCanShowEventDeniedShow = false;

    /**
     * This holds the settings for if a layer should hide (or not) when we click outside of it.
     * @type {boolean}
     * @private
     */
    this._clickToHide = false;

    /**
     * Whether, for lightbox-enabled layers, we've set the initial focus yet.
     *
     * @type {boolean}
     * @private
     */
    this._initialFocusHandled = false;

    // INITIALIZATION LOGIC
    // --------------------

    var hasPostMessage =
      typeof window.postMessage !== 'undefined' && window.postMessage !== null;

    var query = {
      xdm: 'edr',
    };
    if (id) {
      query['lid'] = id;
    }
    if (rootLanguage) {
      query['rlang'] = rootLanguage;
    }

    EDRSurveyCode.Tester.log(5, this.logId, query['xdm']);

    var div = (this.div = document.createElement('div')),
      xdm = window['eDRXDMClient'],
      head = document.getElementsByTagName('head')[0],
      style = (this._styleBlock = document.createElement('style')),
      thisLayer = this,
      rpc;

    // the containing div for all this layer's stuff
    div.id = this._divId;
    div.className = 'edr_lwrap';
    div.setAttribute('title', 'Online Quality Survey');
    div.setAttribute('aria-label', 'Online Quality Survey');
    div.setAttribute('role', 'alertdialog');
    document.getElementById('edr_survey').appendChild(div);

    var that = this;

    this._rpc = rpc = new xdm.Rpc(
      /** The channel configuration **/
      {
        remote: EDRSurveyCode.Main.buildUrl('l', query),
        container: this._divId,
        props: { id: this._iframeId },

        onReady: function () {
          rpc['hostReady']();
        },
      },
      /** The interface configuration */
      {
        remote: {
          alertMessage: {},
          hostReady: {},
          onShown: {},
          onHidden: {},
          requestMetrics: {},
          onAnchorEdgesChanged: {},
          hide: {},
          prepareSurvey: {},
          setHoverState: {},
          setFocusState: {},
          onConfigured: {},
          focusFirst: {},
        },
        local: {
          alertMessage: function (msg) {
            alert('received by survey code: ' + msg);
          },
          initialise: function (rootDeploymentId, rootLanguage) {
            thisLayer.initialise(rootDeploymentId, rootLanguage);
          },
          configureLayer: function (
            name,
            iframeStyles,
            showRules,
            layout,
            deploy,
            probes
          ) {
            thisLayer.configure(
              name,
              iframeStyles,
              showRules,
              layout,
              deploy,
              probes
            );
          },
          hide: function () {
            thisLayer.hide();
          },
          forceHide: function () {
            thisLayer.forceHide();
          },
          showLightbox: function () {
            thisLayer.showLightbox();
          },
          hideLightbox: function () {
            thisLayer.hideLightbox();
          },
          destroyLayer: function () {
            thisLayer.destroy('close');
          },
          deployAdditionalLayers: function (layerIds) {
            for (var i = 0; i < layerIds.length; i++) {
              EDRSurveyCode.Main.deployLayer(layerIds[i]);
            }
          },
          deployAdditionalDeployments: function (deploymentIds) {
            for (var i = 0; i < deploymentIds.length; i++) {
              EDRSurveyCode.Main.deployDeployment(
                deploymentIds[i],
                that._rootDeploymentId,
                that._rootLanguage
              );
            }
          },
          triggerLayerByName: function (layerName) {
            var layer = EDRSurveyCode.Main.getLayerByName(layerName);
            if (layer) {
              layer.trigger(thisLayer.div);
            }
          },
          setMetrics: function (size, laterRects) {
            thisLayer._setMetrics(size, laterRects);
          },
          activateTestMode: function (messageStrings) {
            EDRSurveyCode.Tester.activate(messageStrings);
          },
          execute: function () {
            thisLayer.execute();
          },
          logServerItems: function (items) {
            for (var i = 0; i < items.length; ++i) {
              EDRSurveyCode.Tester.serverLog(thisLayer.logId, items[i]);
            }
          },
          updateCookie: function (newValue) {
            EDRUtility.createCookie(
              EDRSurvey.COOKIE_NAME,
              newValue,
              730,
              EDRSurveyCode.Main.DOMAIN
            );
          },
          log: function (msg, type) {
            EDRSurveyCode.Tester.providerLog(thisLayer.logId, msg, type);
          },
        },
      }
    );

    // create a style block to help position the layer - initially hides everything
    style.type = 'text/css';
    head.appendChild(style);
    EDRUtility.setStyleBlock(
      style,
      '#' + this._divId + ' {' + this.HIDDEN_STYLE + '}'
    );

    // under some browsers, easyXDM can take a while to create the IFRAME as it's waiting for it's own DOMReady
    // which takes more things into account than ours...
    var fnWaitForIFrame = function () {
      that.iframe = document.getElementById(that._iframeId);
      if (that.iframe) {
        that.iframe.setAttribute('scrolling', 'no');

        EDRSurveyCode.Main.onLayerInitialised(id, that);

        var check = function () {
          // check if iframe has loaded
          var doc = null;
          try {
            // try to write a random piece of data to the iframe's javascript namespace. This will fail (with a
            // catchable exception) when the request completes (as the iframe's origin changes at this point).
            doc = that.iframe.contentWindow;
            var key = +new Date() + '' + Math.random();
            doc[key] = 'testdata';
          } catch (e) {
            // We expect to get a 'SecurityError' here when the iframe's web request has completed (whether it contained any content or not)
            // because the iframe now contains x-domain content and we tried to access it.
            doc = null;
          }

          // This is supposed to be if(doc) and NOT if(!doc). See method comment.
          if (doc) {
            that._iframeCheckTimeoutTimer = setTimeout(check, 10);
          } else {
            // We have no longer access to the iframe, wait at most half a second for the
            // layer to be ready, if not destroy it.
            that._iframeCheckTimeoutTimer = setTimeout(function () {
              if (!that._initialised) {
                EDRSurveyCode.Tester.log(42, that.logId, [
                  that.MAX_WAIT_FOR_LOAD,
                ]);
                EDRSurveyCode.EventHub.raise(
                  EDRSurveyCode.EventHub.LAYER_WONT_SHOW,
                  { layer: this }
                );
                that.destroy();
              }
            }, that.MAX_WAIT_FOR_LOAD);
          }
        };
        that._iframeCheckTimeoutTimer = setTimeout(check, 10);

        // start a timer which will destroy this layer if it's not configured by the server within a specific time
        that._configureTimeoutTimer = setTimeout(function () {
          EDRSurveyCode.Tester.log(12, that.logId, null, 4);
          // The layer won't be shown also in this case, so trigger the event
          EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.LAYER_WONT_SHOW, {
            layer: this,
          });
          that.destroy();
        }, that.MAX_WAIT_FOR_CONFIGURE);

        // start "ticking"
        that._tick();
      } else {
        setTimeout(fnWaitForIFrame, 10);
      }
    };

    fnWaitForIFrame();
    EDRSurveyCode.Tester.log(6, this.logId);
  };

  /**
   * Returns the name of this layer. Part of the survey code's public interface.
   * @public
   * @return {String}
   */
  EDRSurveyCode.Layer.prototype.getName = function () {
    return this.name;
  };

  /**
   * Returns the depolyment object. Part of the survey code's public interface.
   * @public
   * @return {EDRSurveyCode.Deployment}
   */
  EDRSurveyCode.Layer.prototype.getDeployment = function () {
    return new EDRSurveyCode.Deployment(this._deploy);
  };

  /**
   * Request that the layer's javascript code return it's current size and execute button metrics.
   * This method is asynchronous; results will be returned by calling back fnCallback with two arguments.
   */
  EDRSurveyCode.Layer.prototype.requestCurrentMetrics = function () {
    this._rpc['requestMetrics']();
  };

  /**
   * Set the hover state of the button.
   */
  EDRSurveyCode.Layer.prototype.setHoverState = function (edrId, hovered) {
    this._rpc['setHoverState'](edrId, hovered);
  };

  /**
   * Set the focus state of the button.
   */
  EDRSurveyCode.Layer.prototype.setFocusState = function (edrId, hovered) {
    this._rpc['setFocusState'](edrId, hovered);
  };

  /**
   * Event notification method called when the positioner has changed the anchor edges of the layer.
   * Used to update the layer's HTML DOM with a css class which can change the styling of the actual layer.
   * @param {Array}  edges  The current anchored edges.
   */
  EDRSurveyCode.Layer.prototype.onAnchorEdgesChanged = function (edges) {
    this._rpc['onAnchorEdgesChanged'](edges);
  };

  /**
   * Do basic layer initialisation.
   *
   * This is called as soon as the survey code survey code knows that it will actually show a layer.
   * This doesn't cause the layer to be ready to display, for that configure() needs to be called.
   *
   * @param {string|null} rootDeploymentId  The root deployment Id that the server knows about, if any.
   * @param {string|null} rootLanguage          The locale we need to use in this deployment and followings, if any.
   */
  EDRSurveyCode.Layer.prototype.initialise = function (
    rootDeploymentId,
    rootLanguage
  ) {
    if (!this._rootDeploymentId && rootDeploymentId) {
      // rootDeploymentId will not be set by this point if this is an initial layer (as initial layers have no
      // constructor arguments). If this is the case, we can set it here.
      this._rootDeploymentId = rootDeploymentId;
    }

    if (rootLanguage) {
      this._rootLanguage = rootLanguage;
    }

    this._initialised = true;
  };

  /**
   * This method is called from the layer itself, after it has initialised.
   * It gives this survey code all the important information about the layer required to show and position it correctly.
   * @param {String}  name          The name of the layer - can be different to it's ID.
   * @param {Object}  iframeStyles  The styles which should be applied to the containing div.
   * @param {Object}  showRules     The rules which define when this layer will appear.
   * @param {Object}  layout        The layout configuration.
   * @param {Object}  deploy        What to deploy when the layer executes.
   * @param {Object}  probes        A collection of probes.
   */
  EDRSurveyCode.Layer.prototype.configure = function (
    name,
    iframeStyles,
    showRules,
    layout,
    deploy,
    probes
  ) {
    EDRSurveyCode.Tester.log(7, this.logId, name);

    this.name = name;
    this.iframe.className =
      EDRSurveyCode.Main.LAYER_IFRAME_CLASS + ' edr_layer_name_' + name;
    this._iframeStyles = iframeStyles;
    this._layout = layout;
    this._deploy = deploy;
    this._probeConfiguration = probes;
    this._deploymentId = this._parseDeploymentId(
      this._deploy['deploymentId']
    )[0];

    // create or delete the lightbox html element as appropriate
    if (layout['lightbox'] && !this._lightboxElement) {
      this._createLightbox();
    } else if (!layout['lightbox'] && this._lightboxElement) {
      this._destroyLightbox();
    }

    // Enable clicking anywhere to hide the layer
    if (layout['clickToHide']) {
      this._clickToHide = true;
    }

    // Enable specific JS behaviours
    this._configureJSBehaviour();

    // create classes to manage each trigger and conditional
    this._triggers = [new EDRSurveyCode.TriggerManual()];
    this._globalConditionals = [];
    if (showRules) {
      var ts = showRules['triggers'],
        cs = showRules['globalConditionals'],
        i;
      if (ts) {
        for (i = 0; i < ts.length; ++i) {
          this._triggers.push(EDRSurveyCode.Trigger.create(ts[i]));
        }
      }
      if (cs) {
        for (i = 0; i < cs.length; ++i) {
          this._globalConditionals.push(
            EDRSurveyCode.Conditional.create(cs[i])
          );
        }
      }
    }

    var css = '#' + this._divId + '{';

    // always explicitly hide at this css style block level, creating the EDRSurveyCode.PositionedLayer class will actually show it
    css +=
      this.HIDDEN_STYLE + 'z-index:' + EDRSurveyCode.Main.ZINDEX_ON_TOP + ';';
    //remove any inline styles that may have been applied if configured earlier (i.e while lazy loading).
    var lwrapdiv = document.getElementById(this._divId);
    if (lwrapdiv) {
      lwrapdiv.removeAttribute('style');
    }
    // create styles
    for (var style in this._iframeStyles) {
      if (this._iframeStyles.hasOwnProperty(style)) {
        css += style + ':' + this._iframeStyles[style] + ';';
      }
    }

    css += '}';

    EDRUtility.setStyleBlock(this._styleBlock, css);

    // Set initial _everShownPersisted value.
    this.everShown = this._isPersistedCookieSet();

    // Let the server js know we have been configured successfully, return any values we have authority over.
    this._rpc['onConfigured']({
      everShown: this.everShown,
    });
    EDRSurveyCode.EventHub.raise(
      EDRSurveyCode.EventHub.LAYER_INITIALISED,
      this
    );

    if (!this._configured) {
      this._configured = true;
      // we're successfully configured - don't time out
      clearTimeout(this._configureTimeoutTimer);
      clearTimeout(this._iframeCheckTimeoutTimer);
      this._configureTimeoutTimer = null;
      this._iframeCheckTimeoutTimer = null;
    } else if (this.showing) {
      // we're already showing this layer (note: this will happen with lazy-loading layers every time)

      // call "show" so that it resizes itself correctly for the new content
      this._show(this._triggeringElement);
    }
  };

  /**
   * Parses the deploy string for a deployment id.
   *
   * Deploy strings are made up of: <deploymentId>|<deployId>|<triggerpointId>
   * The ids are not guaranteed. (i.e. if a deployment triggers another deployment there will be no deploy/triggerpoint).
   *
   * @param {String}  deployString  The deployment string (as described before)
   * @returns {Array}
   * @private
   */
  EDRSurveyCode.Layer.prototype._parseDeploymentId = function (deployString) {
    if (!deployString) {
      EDRSurveyCode.Tester.log(43, this.logId, null, 3);
      return null;
    }
    return deployString.split('|');
  };

  EDRSurveyCode.Layer.prototype._setOpacity = function (e, opacity, colour) {
    var op = Math.round(opacity * 100);
    EDRUtility.setStyleAttribute(
      e,
      'background-color:' +
        colour +
        ';opacity:' +
        opacity +
        ';filter: alpha(opacity=' +
        op +
        ')' +
        ';display:block;'
    );
  };

  EDRSurveyCode.Layer.prototype._tweenLbOpacity = function (out, fnComplete) {
    var lightboxConfig = this._layout['lightbox'];
    var opacity = parseFloat(lightboxConfig['opacity']);

    clearInterval(this._lightboxAnimationStepper);
    var lerp = out ? 1 : 0;
    var that = this;
    this._lightboxAnimationStepper = setInterval(function () {
      that._setOpacity(
        that._lightboxElement,
        lerp * opacity,
        lightboxConfig['colour']
      );
      lerp += !out ? 0.05 : -0.05;
      if ((!out && lerp >= 1) || (out && lerp <= 0)) {
        clearInterval(that._lightboxAnimationStepper);
        that._lightboxAnimationStepper = null;
        if (fnComplete) {
          fnComplete();
        }
      }
    }, 10);
  };

  /**
   * Returns if we have set a persist cookie for this deployment.
   * @private
   */
  EDRSurveyCode.Layer.prototype._isPersistedCookieSet = function () {
    var existingPersistCookie = EDRUtility.readCookie(
      EDRSurveyCode.Main.COOKIE_FORCED_CONFIG_PREFIX + this.getDeploymentId()
    );
    return !!existingPersistCookie;
  };

  /**
   * Show the layer lightbox, if one is configured.
   * @public
   */
  EDRSurveyCode.Layer.prototype.showLightbox = function () {
    if (!this._lightboxElement) {
      return;
    }
    var lightboxConfig = this._layout['lightbox'];
    if (lightboxConfig['fade']) {
      this._tweenLbOpacity(false);
    } else {
      this._setOpacity(
        this._lightboxElement,
        lightboxConfig['opacity'],
        lightboxConfig['colour']
      );
    }

    EDRSurveyCode.Main.flagLightboxDisplayed(this.id);
  };

  /**
   * Hide the layer lightbox.
   * @public
   */
  EDRSurveyCode.Layer.prototype.hideLightbox = function () {
    if (this._lightboxElement) {
      // hide completely function
      var that = this;
      var fnHide = function () {
        EDRUtility.setStyleAttribute(that._lightboxElement, '');
        EDRSurveyCode.Main.flagLightboxHidden(that.id);
      };

      if (this._layout['lightbox']['fade']) {
        this._tweenLbOpacity(true, fnHide);
      } else {
        fnHide();
      }
    }
  };

  /**
   * Manually trigger the layer.
   * @param {Element=}  triggeringElement  (Optional) The DOMElement which caused the triggering.
   */
  EDRSurveyCode.Layer.prototype.trigger = function (triggeringElement) {
    // trigger the "manual" trigger
    this._triggers[0].setTriggered(triggeringElement);
  };

  /**
   * Event notification; will fire whenever there is an event on the window object - can be used to trigger the layer.
   * @param {Event}  e  The event.
   */
  EDRSurveyCode.Layer.prototype.onEvent = function (e) {
    if (!this._configured) {
      return;
    }

    if (
      e.type === 'keyup' &&
      e.keyCode === 27 &&
      this._lightboxElement &&
      this._lightboxElement.offsetWidth
    ) {
      // allow <ESC> to close modal layers (having a lightbox means it's modal)
      this.destroy('close');
    }

    if (
      e.target &&
      e.target.classList.contains(EDRSurveyCode.Main.GO_CLICK_CAPTURER_CLASS) &&
      this._positioner
    ) {
      if (!this._positioner.isClickCapturer(e.target)) {
        return;
      }
      switch (e.type) {
        case 'click':
          this.execute(); // yes it's one of ours - execute the layer
          break;
        case 'mouseover':
          this.setHoverState(e.target.id, true);
          break;
        case 'mouseout':
          this.setHoverState(e.target.id, false);
          break;
        case 'focus':
          this._clickCapturerFocusChanged(e.target.id, true);
          break;
        case 'blur':
          this._clickCapturerFocusChanged(e.target.id, false);
          break;
      }
    } else {
      if (e.type === 'click') {
        if (this._clickToHide && this.showing) {
          this.hide();
        }
      }
    }

    for (var i = 0; i < this._triggers.length; ++i) {
      this._triggers[i].onEvent(e, EDRSurveyCode.PageState, this);
    }
  };

  EDRSurveyCode.Layer.prototype._clickCapturerFocusChanged = function (
    id,
    state
  ) {
    var that = this;
    setTimeout(function () {
      // cannot synchronously check document.activeElement as it lags behind the event call
      if (document.activeElement === that.iframe) {
        // our layer IFRAME has been focused, ask the layer to focus its first element
        that.focusFirst();
      }
    });

    this.setFocusState(id, state);
  };

  /**
   * Periodically called on a timer; used for various layer functionality.
   * @private
   */
  EDRSurveyCode.Layer.prototype._tick = function () {
    clearTimeout(this._ticker);

    if (this._configured) {
      var evt = { layer: this, canShow: true };
      EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.LAYER_CAN_SHOW, evt);

      var conditionalState = evt['canShow'];

      if (conditionalState) {
        // check all global conditionals are true
        for (var u = 0; u < this._globalConditionals.length; ++u) {
          if (!this._globalConditionals[u].isTrue(null)) {
            conditionalState = false;
            break;
          }
        }
      } else if (!this._loggedCanShowEventDeniedShow) {
        EDRSurveyCode.Tester.log(31, this.logId, null, 4);
        this._loggedCanShowEventDeniedShow = true;
      }

      if (conditionalState) {
        if (this._globalConditionalsHidShowingLayerTrigger) {
          this._show(
            this._globalConditionalsHidShowingLayerTrigger === true
              ? null
              : this._globalConditionalsHidShowingLayerTrigger
          );
        } else {
          // evaluate all triggers
          for (var i = 0; i < this._triggers.length; ++i) {
            this._triggers[i].evaluate(EDRSurveyCode.PageState, this);
          }

          // see if it's triggered
          var triggeringElement = null;
          var triggerStateChanged = false;
          var triggered = false;
          for (i = 0; i < this._triggers.length; ++i) {
            var tr = this._triggers[i];
            if (!tr.checkTriggerChanged()) {
              continue;
            }
            triggerStateChanged = true;
            var e = this._triggers[i].getTriggeringElement();
            if (e) {
              triggered = true;
              if (e !== window) {
                triggeringElement = e;
              }
            }
          }

          if (triggerStateChanged) {
            if (
              this.showing &&
              triggeringElement &&
              triggeringElement !== this._triggeringElement
            ) {
              // different triggering element - hide before showing again below
              this.forceHide(true); // the "true" makes hide() leave the trigger states alone
            }

            // show/hide the layer
            if (triggered && !this.showing) {
              this._show(triggeringElement);
            } else if (!triggered && this.showing) {
              this.hide();
            }
          }
        }

        this._globalConditionalsHidShowingLayerTrigger = null;
      } else if (this.showing) {
        // global conditional fail while showing - hide, and then make a note to show it again when conditionals pass again
        this._globalConditionalsHidShowingLayerTrigger = this._triggeringElement
          ? this._triggeringElement
          : true;
        this.forceHide(true); // the "true" makes hide() leave the trigger states alone
      }
    }

    var that = this;
    this._ticker = setTimeout(function () {
      that._tick();
    }, 100);
  };

  /**
   * Show the layer.
   * @param {Element}  triggeringElement  The DOM element responsible for triggering the layer to show.
   * @private
   * @return {Boolean} Returns true if the layer was shown.
   */
  EDRSurveyCode.Layer.prototype._show = function (triggeringElement) {
    EDRSurveyCode.Tester.log(8, this.logId, triggeringElement);

    if (!this._configured) {
      // not configured yet - cannot show
      return false;
    }

    var layout = EDRUtility.clone(this._layout);
    var rel = layout['position']['relativeTo'];
    if (!rel) {
      this.destroy();
      return false;
    }

    // relativeElement will be set to the DOM element which this layer should be "anchored" to, if any
    var relativeElement;

    switch (rel) {
      case 'window':
        relativeElement = window;
        break;
      case 'document':
        relativeElement = window.document;
        break;
      default:
        // rel is either "trigger" or a css selector string
        relativeElement =
          rel === 'trigger' ? triggeringElement : document.querySelector(rel);

        if (!relativeElement) {
          // layer must be positioned next to an element but it could not be found...
          // ...fall back to positioning the layer in the middle of the window
          relativeElement = window;
          layout['position']['edges'] = ['top', 'left', 'bottom', 'right'];
          layout['position']['offset'] = { x: 0, y: 0 };
        }
    }

    if (this.showing) {
      if (!this._positioner) {
        throw 'EDRSurveyCode.PositionedLayer not available when showing in show()';
      }
      // the relativeElement may have changed - recreate the positioner
      this._positioner.destroy();
    } else {
      // remove visibility: hidden from the div style block
      EDRUtility.setStyleAttribute(this.div, this.HIDDEN_STYLE);

      if (this._layout['lightbox'] && this._layout['lightbox']['show']) {
        this.showLightbox();
      }
    }

    this._positioner = new EDRSurveyCode.PositionedLayer(
      this,
      relativeElement,
      this._iframeStyles,
      layout
    );

    this.showing = true;

    this._triggeringElement = triggeringElement;

    // First time showing on this page, is it also the first time we have seen this persisted layer?
    var shownPersisted = true;
    if (!this.everShown) {
      shownPersisted = this._isPersistedCookieSet();
    }
    this.everShown = shownPersisted;

    // Set persist cookie
    if (this._deploy['persistDeployment'] && this.getDeploymentId()) {
      this._setPersistenceCookie();
    }

    // Tell the surveycode.srv about our entry data in-case it wants to use it!
    // Example: embedded surveys need to have this posted by surveycode.srv to prepare.php before it can display.
    var entryData = this._getSurveyEntryData();
    entryData['surveyId'] = this._deploy['surveyId'];
    entryData['installationId'] = this._deploy['installationId'];
    entryData['deploymentId'] = this.getDeploymentId();
    entryData['rootDeploymentId'] = this._rootDeploymentId;

    this._rpc['onShown'](entryData);

    // Log this show event
    if (!this.everShown) {
      EDRUtility.postToUrl(
        EDRSurveyCode.Main.eDRUrlBase + 'log.php?e=layershow',
        this._deploy,
        EDRSurveyCode.PageState.documentDomainExplicitlySet
      );
    }

    EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.LAYER_SHOWN, {
      layer: this,
      firstShow: !this.everShown,
      firstShowOnPage: !this.everShownOnPage,
    });

    this.everShown = this.everShownOnPage = true;

    return true;
  };

  EDRSurveyCode.Layer.prototype._removePersistenceCookie = function (
    deploymentId
  ) {
    var cookieName =
      EDRSurveyCode.Main.COOKIE_FORCED_CONFIG_PREFIX + deploymentId;
    var existingCookie = EDRUtility.readCookie(cookieName);
    if (existingCookie) {
      EDRUtility.eraseCookie(cookieName, EDRSurveyCode.Main.DOMAIN);
    }
  };

  EDRSurveyCode.Layer.prototype._setPersistenceCookie = function () {
    var deploymentId = this.getDeploymentId();
    if (!deploymentId) {
      EDRSurveyCode.Tester.log(41, this.logId);
      return;
    }
    var cookieName =
      EDRSurveyCode.Main.COOKIE_FORCED_CONFIG_PREFIX + deploymentId;
    var forever = 63072000; // We'll actually set it to about 2 years (in seconds).
    var durationSeconds = this._deploy['persistDeploymentDuration'];
    var existingCookie = EDRUtility.readCookie(cookieName);

    if (existingCookie) {
      return;
    }

    var cookieDurationInDays = false;
    if (durationSeconds !== false) {
      // false = session.
      if (durationSeconds === 0) {
        // 0 = forever.
        durationSeconds = forever;
      }
      cookieDurationInDays = durationSeconds / 86400;
    }

    // Use the forced deployment cookie value to store the root deployment id if any.
    var cookieValue = JSON.stringify({
      rdi: this._rootDeploymentId ? this._rootDeploymentId : 1,
      rl: this._rootLanguage ? this._rootLanguage : '',
    });

    // Create cookies:
    EDRUtility.createCookie(
      cookieName,
      cookieValue,
      cookieDurationInDays,
      EDRSurveyCode.Main.DOMAIN
    );

    // Log
    EDRSurveyCode.Tester.log(39, this.logId, [
      this.getDeploymentId(),
      cookieValue,
    ]);
  };

  /**
   * Returns the deploymentId of the layer (if one exists).
   * @returns {integer}
   */
  EDRSurveyCode.Layer.prototype.getDeploymentId = function () {
    return this._deploymentId;
  };

  /**
   * Request the the layer hides. This calls the layer first, just in case it wants to animate it's "hiding".
   */
  EDRSurveyCode.Layer.prototype.hide = function () {
    if (this._hiding) {
      // already hiding
      return;
    }

    EDRSurveyCode.Tester.log(9, this.logId);

    this._hiding = true;

    this.hideLightbox();

    // give the layer a chance to hide itself - this will only be a maximum of 2 seconds
    this._rpc['hide']();

    var that = this;
    window.setTimeout(function () {
      if (that._hiding) {
        EDRSurveyCode.Tester.log(13, that.logId, [that.MAX_HIDE_TIME], 4);

        // window didn't call back to us with forceHide within 2 seconds, do it ourselves
        that.forceHide();
      }
    }, this.MAX_HIDE_TIME);
  };

  /**
   * Hide the layer - can be shown again with show().
   * @param {Boolean=} ignoreTriggers (Optional) If true, calling this will not reset the triggers.
   */
  EDRSurveyCode.Layer.prototype.forceHide = function (ignoreTriggers) {
    this._hiding = false;

    if (!this.showing) {
      // nothing to do
      return;
    }

    EDRSurveyCode.Tester.log(10, this.logId);

    if (!ignoreTriggers) {
      // untrigger all triggers
      for (var i = 0; i < this._triggers.length; ++i) {
        this._triggers[i].setUntriggered();
      }
    }

    // The visibility:hidden; here is needed along with the normal hidden style because otherwise the Android tablet
    // browser has been shown to not properly hide the layer, causing all kinds-of strange graphical corruption issues.
    // We can't add this style to the HIDDEN_STYLE constant as if the IFRAME is effectively hidden at the start, IE
    // does not even allow it to create any network connections!
    EDRUtility.setStyleAttribute(
      this.div,
      this.HIDDEN_STYLE + 'visibility:hidden;'
    );

    this.showing = false;
    this._loggedCanShowEventDeniedShow = false;
    this._triggeringElement = false;
    this._initialFocusHandled = false;

    if (this._positioner) {
      this._positioner.destroy();
      this._positioner = null;
    }

    this._rpc['onHidden']();

    EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.LAYER_HIDDEN, {
      layer: this,
    });
  };

  EDRSurveyCode.Layer.prototype.focusFirst = function () {
    this._rpc['focusFirst']();
  };

  EDRSurveyCode.Layer.prototype._setMetrics = function (size, laterRects) {
    if (this._positioner) {
      this._positioner.setMetrics(size, laterRects);

      // now that the cick capturers are guaranteed to be created (caused by setMetrics() above), handle the inital focus
      this._handleInitialFocus();
    }
  };

  EDRSurveyCode.Layer.prototype._handleInitialFocus = function () {
    // we want to focus if this layer is configured with a lightbox and we haven't done it yet since the layer was shown

    if (
      this._positioner &&
      this._lightboxElement &&
      !this._initialFocusHandled
    ) {
      this._initialFocusHandled = true;
      this._positioner.focusFirstClickCapturer();
    }
  };

  /**
   * Permanently hide and destroy the layer.
   *
   * @param action  The action causing this destroy.
   */
  EDRSurveyCode.Layer.prototype.destroy = function (action) {
    EDRSurveyCode.Tester.log(11, this.logId);

    this._runDePersist();

    clearTimeout(this._ticker);
    if (this._configureTimeoutTimer) {
      clearTimeout(this._configureTimeoutTimer);
    }

    if (this._iframeCheckTimeoutTimer) {
      clearTimeout(this._iframeCheckTimeoutTimer);
    }

    if (this._positioner) {
      this._positioner.destroy();
    }
    if (this.iframe && this.iframe.parentNode) {
      this.iframe.parentNode.removeChild(this.iframe);
    }
    if (this._styleBlock && this._styleBlock.parentNode) {
      this._styleBlock.parentNode.removeChild(this._styleBlock);
    }
    if (this.div && this.div.parentNode) {
      this.div.parentNode.removeChild(this.div);
    }
    if (this._lightboxElement) {
      this._destroyLightbox();
    }

    this._ticker = null;
    this._configureTimeoutTimer = null;
    this._iframeCheckTimeoutTimer = null;
    this._positioner = null;
    this.iframe = null;
    this.div = null;
    this._styleBlock = null;
    this._rpc = null;
    this._lightboxElement = null;

    EDRSurveyCode.Main.onLayerDestroyed(this.id);

    EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.LAYER_DESTROYED, {
      layerName: this.getName(),
    });

    if (undefined !== action) {
      EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.LAYER_INTERACTED, {
        layer: this,
        action: action,
      });
    }
  };

  EDRSurveyCode.Layer.prototype._runDePersist = function () {
    if (!this._deploy['depersistDeploymentId']) {
      return; //nothing to do.
    }

    var dePersistId = this._deploy['depersistDeploymentId'];
    this._deploy['depersistDeploymentId'] = null; //this is really important to stop looping.

    // Destroy layer.
    var l = EDRSurveyCode.Main.getLayerByDeploymentId(dePersistId);
    if (l) {
      l.destroy();
    }

    // Remove cookie.
    this._removePersistenceCookie(dePersistId);

    // Log
    EDRSurveyCode.Tester.log(40, this.logId, dePersistId);
  };

  /**
   * "Execute" the layer - this would usually start the survey.
   */
  EDRSurveyCode.Layer.prototype.execute = function () {
    if (this._deploy) {
      EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.LAYER_EXECUTED, {
        layer: this,
        action: this._deploy['type'],
      });
      EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.LAYER_INTERACTED, {
        layer: this,
        action: 'executed',
      });

      this._runDePersist();

      switch (this._deploy['type']) {
        case 'triggerOtherLayer':
          var layer = EDRSurveyCode.Main.getLayerByName(
            this._deploy['otherLayerName']
          );
          if (layer) {
            layer.trigger(this.div);
          }
          break;
        case 'deployOtherDeployment':
          var deploymentLayer = EDRSurveyCode.Main.getLayerById(
            this._deploy['onExecuteDeploymentId']
          );
          if (deploymentLayer) {
            EDRSurveyCode.Tester.log(
              38,
              this.logId,
              this._deploy['onExecuteDeploymentId']
            );
            deploymentLayer.trigger(this.div);
          } else {
            EDRSurveyCode.Tester.log(
              36,
              this.logId,
              this._deploy['onExecuteDeploymentId']
            );

            // If we're deploying another layer then whatever caused this layer to be deployed should also
            // be passed on to new layers spawned from this one as a "root" deployment ID.
            // This will be ourselves if this layer was triggered directly (via trigger point).
            var rootDeploymentId = this._deploy['rootDeploymentId']
              ? this._deploy['rootDeploymentId']
              : this._rootDeploymentId // If the page has not been reloaded we must check the class property
              ? this._rootDeploymentId
              : this._deploy['deploymentId'];

            var rootLanguage = this._deploy['rootLanguage']
              ? this._deploy['rootLanguage']
              : this._rootLanguage;

            EDRSurveyCode.Main.deployDeployment(
              this._deploy['onExecuteDeploymentId'],
              rootDeploymentId,
              rootLanguage
            );
          }
          break;
        case 'popupSurvey':
          this._deployPopupSurvey(this._deploy);
          break;

        case 'exitSurvey':
          this._deployExitSurvey(this._deploy);
          break;

        case 'embeddedSurvey':
          // We shouldn't have an execute for embedded surveys!
          // Do nothing!
          break;
      }

      if (this._deploy['keepLayerOnExecute']) {
        EDRSurveyCode.Tester.log(37, this.logId);
      } else {
        this.destroy();
      }
    } else {
      // debug catchall for now
      this.destroy();
    }
  };

  EDRSurveyCode.Layer.prototype._deployPopupSurvey = function (
    deploy,
    holdingWin
  ) {
    var guid = EDRUtility.genGuid();

    // tell a popup window (open new one if necessary) to display the survey using the same GUID that we passed to
    // prepare.php above
    var enterUrl =
      EDRSurveyCode.Main.eDRUrlBase +
      'deploy/' +
      (holdingWin ? 'enter-exit-survey-no-holding' : 'enter') +
      '/guid/' +
      guid +
      '/installation/' +
      deploy['installationId'];

    var win = this._surveyWin,
      size = this._deploy['windowSize'];

    // If the current device continues to execute javascript on inactive tabs then we ensure a zero race condition
    // situation by only redirecting to the survey once the entry data has been successfully posted (async === true).
    // If the current device suspends js execution on inactive tabs (iOS <= 7), then opening the new window must be the
    // last thing we do and must be *synchronous* with the execution of this method, which is itself synchronous with the
    // user button click (avoids triggering popup blockers). This means we can't use the async callback on EDRUtility.postToUrl()
    // and therefore there's no way to guarantee that the entry data has been successfully posted before the window is
    // opened.
    var async = EDRUtility.executesInactiveTabs();

    // dispatch a POST request to eDR servers containing the information needed to start the survey
    var entryData = this._getSurveyEntryData();
    entryData['surveyId'] = deploy['surveyId'];
    entryData['installationId'] = deploy['installationId'];
    entryData['deploymentId'] = deploy['deploymentId'];
    entryData['rootDeploymentId'] = this._rootDeploymentId;

    EDRUtility.postToUrl(
      EDRSurveyCode.Main.eDRUrlBase + 'prepare.php?guid=' + guid,
      entryData,
      EDRSurveyCode.PageState.documentDomainExplicitlySet,
      async
        ? function () {
            win.location = enterUrl;
          }
        : undefined
    );
    // tell a popup window (open new one if necessary) to display the survey using the same GUID that we passed to
    if (holdingWin) {
      this._surveyWin = win = holdingWin;
      // we're retasking the holding window to display the survey

      if (size['x'] && size['y']) {
        if (win.resizeTo) {
          win.resizeTo(size['x'], size['y']);
        } else {
          win.outerWidth = size['x'];
          win.outerHeight = size['y'];
        }
      }
    } else {
      this._surveyWin = win = this._openWindow(
        async ? 'about:blank' : enterUrl,
        'edr_win_' + this.name,
        size['x'],
        size['y']
      );
    }
  };

  EDRSurveyCode.Layer.prototype._configureJSBehaviour = function () {
    switch (this._layout['jsBehaviour']) {
      case 0: // NONE
        break;
      case 1: //FLOATING
        this._configureFloatingLayerBehaviour();
        break;
      default:
        break;
    }
  };

  EDRSurveyCode.Layer.prototype._createLightbox = function () {
    var lb = document.createElement('div');
    lb.className = 'edr_lb';
    document.getElementById('edr_survey').appendChild(lb);
    this._lightboxElement = lb;
  };

  EDRSurveyCode.Layer.prototype._destroyLightbox = function () {
    if (this._lightboxAnimationStepper) {
      clearInterval(this._lightboxAnimationStepper);
      this._lightboxAnimationStepper = null;
    }

    EDRSurveyCode.Main.flagLightboxHidden(this.id);
    if (this._lightboxElement && this._lightboxElement.parentNode) {
      this._lightboxElement.parentNode.removeChild(this._lightboxElement);
    }
    this._lightboxElement = null;
  };

  /**
   * This method is a stub for setting up floating/dragging behaviour for a layer (for mobiles/tables).
   */
  EDRSurveyCode.Layer.prototype._configureFloatingLayerBehaviour = function () {
    EDRSurveyCode.Tester.log(35, this.logId);
  };

  EDRSurveyCode.Layer.prototype._deployExitSurvey = function (deploy) {
    EDRSurveyCode.Tester.log(26, this.logId);

    var entryData = this._getSurveyEntryData();
    entryData['surveyId'] = deploy['surveyId'];
    entryData['installationId'] = deploy['installationId'];
    entryData['deploymentId'] = deploy['deploymentId'];
    entryData['rootDeploymentId'] = this._rootDeploymentId;

    // When document.domain has been explicitly set, and we're running under IE we need to use the
    // "access document" to gain access to the popup window's document.
    var useAccessDoc =
      EDRSurveyCode.PageState.documentDomainExplicitlySet &&
      EDRUtility.isIEOrEdge();

    var size = this._deploy['holdingWindowSize'];
    // Triggering from the visibiltychange event ensures that we write into the holding page document before the
    // JS on the triggering page is being paused by iOS
    var triggeredWrite = false,
      eventNames = EDRUtility.getVisibilityChangeEventNames();
    eventWriteFn = function (evt) {
      evt = evt || window.event;
      if (evt.type === eventNames.visibilitychange) {
        window.document.removeEventListener(
          eventNames.visibilitychange,
          eventWriteFn
        );
        if (!triggeredWrite) {
          fn(that._surveyWin);
        }
      }
    };

    if (eventNames.hidden in window.document) {
      window.document.addEventListener(
        eventNames.visibilitychange,
        eventWriteFn
      );
    }

    // We use _blank intentionally here as Chrome on iOS 9 returns undefined otherwise
    this._surveyWin = this._openWindow(
      useAccessDoc
        ? EDRSurveyCode.Main.holdingWindowAccessDocument
        : 'about:blank',
      '_blank',
      size['x'],
      size['y']
    );

    // be prepared for the window not having a document property
    // IE doesn't have it until the page has loaded
    // this var is used to see if we've got out target doc yet
    var originalDocument;
    try {
      originalDocument = this._surveyWin.document;
    } catch (e) {
      originalDocument = null;
    }

    // wait to gain access to the holding window's content (this may take a short while if an access document is used)
    var attempts = 0,
      maxTime = 2000,
      delay = 20;
    var that = this;
    function fn(win) {
      if (++attempts > maxTime / delay) {
        if (!triggeredWrite) {
          win.close();
          win = null;

          // failed to gain access to the popup window within the timeout
          EDRSurveyCode.Tester.log(24, that.logId, null, 3);
        }
        return;
      }

      try {
        if (
          !win.document ||
          (triggeredWrite && originalDocument === win.document)
        ) {
          throw 'wait';
        }
      } catch (e) {
        // no access yet - wait a little while longer...
        setTimeout(function () {
          fn(win);
        }, delay);
        return;
      }

      if (!win.document.getElementById) {
        // holding page cannot function without access to getElementById() - fall back to a normal popup survey
        EDRSurveyCode.Tester.log(25, that.logId, null, 3);
        that._deployPopupSurvey(deploy, win);
      } else {
        EDRSurveyCode.Tester.log(27, that.logId);
        triggeredWrite = true;

        // got access to the holding window document, clear it's content and request the holding page html
        // content from the server
        try {
          // clear current content if we can
          var body = win.document.getElementsByTagName('body');
          if (body) {
            body[0].innerHTML = '';
          }
        } catch (e) {}

        var url =
          EDRSurveyCode.Main.eDRUrlBase +
          'deploy/holding/deployment/' +
          deploy['deploymentId'];

        if (that._rootDeploymentId) {
          url += '/rdi/' + that._rootDeploymentId;
        }

        url += '/survey/' + encodeURIComponent(deploy['surveyId']);
        url += '/installation/' + encodeURIComponent(deploy['installationId']);

        if (EDRSurveyCode.PageState.documentDomainExplicitlySet) {
          url += '/doc-domain/' + encodeURIComponent(document.domain);
        }

        if (entryData['locale']) {
          url += '/locale/' + encodeURIComponent(entryData['locale']);
        }

        originalDocument = win.document;
        win.document.write(
          '<!DOCTYPE html>' + // HTML5 document prologue to stop IE going into quirks mode
            '<SCR' +
            'IPT LANGUAGE="Javascript">' +
            (EDRSurveyCode.PageState.documentDomainExplicitlySet
              ? 'document.domain = "' + document.domain + '";'
              : '') +
            'var pc = ' +
            JSON.stringify(that._probeConfiguration) +
            ';' +
            'var ppv = ' +
            JSON.stringify(EDRSurveyCode.Main.pushedProbeValues) +
            ';' +
            'var sed = ' +
            JSON.stringify(entryData) +
            ';' +
            'var dply = ' +
            JSON.stringify(deploy) +
            ';' +
            '</SCR' +
            'IPT>'
        );
        win.document.write(
          '<SCR' +
            'IPT LANGUAGE="Javascript" ASYNC SRC="' +
            url +
            '"></SCR' +
            'IPT>'
        );

        if (EDRUtility.isIOS()) {
          setTimeout(function () {
            fn(win);
          }, delay);
        }
      }
    }

    // Setting two timeouts here as a fallback in case the visibilitychange event hasn't been called yet.
    // We are using two for iOS 9 Chrome as writing any earlier than this causes a warning message to appear
    setTimeout(function () {
      setTimeout(function () {
        fn(that._surveyWin);
      }, 0);
    }, 0);
  };

  EDRSurveyCode.Layer.prototype._getSurveyEntryData = function () {
    var probesResults = EDRSurveyCodeProbes.execute(
      window,
      EDRSurveyCodeProbes.createFromConfig(this._probeConfiguration),
      EDRSurveyCode.Main.pushedProbeValues
    );

    var uuid = EDRSurveyCode._getId();

    return {
      url: window.location.href,
      probes: probesResults,
      locale: EDRSurveyCode.Main.LOCALE,
      data1: EDRSurveyCode.Main.DATA1,
      data2: EDRSurveyCode.Main.DATA2,
      data3: EDRSurveyCode.Main.DATA3,
      test: EDRSurveyCode.Main.TEST,
      uuid: null !== uuid ? uuid : '',
    };
  };

  EDRSurveyCode.Layer.prototype._openWindow = function (
    url,
    name,
    width,
    height
  ) {
    var isMobile = EDRUtility.isIOS() || EDRUtility.isAndroid();
    var specs = isMobile
      ? null
      : 'location=0,toolbar=no,directories=no,status=no,scrollbars=yes,resizable=yes';

    if (!isMobile && width && height) {
      specs += ',width=' + width + ',height=' + height;
    }

    return window.open(url, name, specs);
  };

  /**
   * Base class which looks after the state of a deployment.
   *
   * @param config The configuration of this deployment.
   *
   * @constructor
   */
  EDRSurveyCode.Deployment = function (config) {
    /**
     * The deployment configuration.
     * @type {Object}
     * @private
     */
    this._config = config;
  };

  /**
   * Get the survey human id.
   *
   * @returns {string}
   */
  EDRSurveyCode.Deployment.prototype.getSurveyId = function () {
    return this._config['surveyId'];
  };

  /**
   * Get the installation human id.
   *
   * @returns {string}
   */
  EDRSurveyCode.Deployment.prototype.getInstallationId = function () {
    return this._config['installationId'];
  };

  /**
   * Get the layer human id.
   *
   * @returns {string}
   */
  EDRSurveyCode.Deployment.prototype.getLayerId = function () {
    return this._config['humanIds']['layerId'];
  };

  /**
   * Get the deployment human id.
   *
   * @returns {String}
   */
  EDRSurveyCode.Deployment.prototype.getDeploymentId = function () {
    return this._config['humanIds']['deploymentId'];
  };

  /**
   * Get the deploy human id.
   *
   * @returns {String}
   */
  EDRSurveyCode.Deployment.prototype.getDeployId = function () {
    return this._config['humanIds']['deployId'];
  };

  /**
   * Get the trigger human id.
   * @returns {String}
   */
  EDRSurveyCode.Deployment.prototype.getTriggerId = function () {
    return this._config['humanIds']['triggerpointId'];
  };

  /**
   * Get the deploy type.
   * @returns {String}
   */
  EDRSurveyCode.Deployment.prototype.getType = function () {
    return this._config['type'];
  };

  /**
   * Base class which looks after the state of a layer trigger.
   *
   * @param config The configuration of this trigger.
   * @constructor
   */
  EDRSurveyCode.Trigger = function (config) {
    /**
     * The trigger configuration.
     * @type {Object}
     * @private
     */
    this._config = config;

    /**
     * The triggering DOM element - null = not triggered.
     * @type {Element}
     * @private
     */
    this._triggeringElement = null;

    /**
     * True if the triggering element has changed and requires some action.
     * @type {Boolean}
     * @private
     */
    this._triggeringElementChanged = false;

    /**
     * An array of conditionals which must pass for this trigger to have any affect.
     * @type {Array.<EDRSurveyCode.Conditional>}
     * @private
     */
    this._conditions = [];

    var cs = config['conditionals'];
    if (cs) {
      for (var i = 0; i < cs.length; ++i) {
        this._conditions.push(EDRSurveyCode.Conditional.create(cs[i]));
      }
    }
  };

  /**
   * Create a new layer from trigger deployment configuration.
   * @static
   * @param {Object} config The trigger configuration.
   * @return {EDRSurveyCode.Trigger}
   */
  EDRSurveyCode.Trigger.create = function (config) {
    switch (config['type']) {
      case 'init':
        return new EDRSurveyCode.TriggerInit(config);
      case 'click':
        return new EDRSurveyCode.TriggerClick(config);
      case 'scroll':
        return new EDRSurveyCode.TriggerScroll(config);

      default:
        EDRSurveyCode.Tester.log(16, null, [config['type']], 2);
    }
  };

  /**
   * Called periodically to evaluate whether this trigger is triggered.
   * @param {EDRSurveyCode.PageState}  pageState  The global state of the page, such as the time since the page was loaded, and the view count.
   * @param {EDRSurveyCode.Layer}      layer      The associated layer.
   */
  EDRSurveyCode.Trigger.prototype.evaluate = function (pageState, layer) {
    // no implementation
  };

  /**
   * Called when a window event fires.
   * Triggers can use this to evaluate whether this event triggers the trigger.
   * @param {Event}                    e          The event instance.
   * @param {EDRSurveyCode.PageState}  pageState  The global state of the page, such as the time since the page was loaded, and the view count.
   * @param {EDRSurveyCode.Layer}      layer      The associated layer.
   */
  EDRSurveyCode.Trigger.prototype.onEvent = function (e, pageState, layer) {
    /* no implementation */
  };

  /**
   * Called from within the trigger to make sure that the conditionals all pass before triggering itself.
   *
   * @param {Element=}  triggeringElement  (Optional) - the triggering element.
   * @private
   * @return {Boolean}
   */
  EDRSurveyCode.Trigger.prototype._doConditionsPass = function (
    triggeringElement
  ) {
    for (var i = 0; i < this._conditions.length; ++i) {
      if (!this._conditions[i].isTrue(triggeringElement)) {
        return false;
      }
    }
    return true;
  };

  /**
   * EDRSurveyCode.Trigger this trigger, optionally with a DOMElement as it's trigger.
   * @param {Element=}  triggeringElement  (optional) The triggering element.
   */
  EDRSurveyCode.Trigger.prototype.setTriggered = function (triggeringElement) {
    var e = triggeringElement || window;
    if (e !== this._triggeringElement) {
      EDRSurveyCode.Tester.log(14, this._config['type'], triggeringElement);
      this._triggeringElementChanged = true;
      this._triggeringElement = e;
    }
  };

  /**
   * Untrigger this trigger.
   */
  EDRSurveyCode.Trigger.prototype.setUntriggered = function () {
    if (this._triggeringElement) {
      EDRSurveyCode.Tester.log(15, this._config['type']);
      this._triggeringElementChanged = true;
      this._triggeringElement = null;
    }
  };

  /**
   * Returns the element which triggered this trigger.
   * null = not triggered, window = triggered (no triggering element), other dom element = triggered (with element).
   * @return {Element}
   */
  EDRSurveyCode.Trigger.prototype.getTriggeringElement = function () {
    return this._triggeringElement;
  };

  /**
   * Returns whether the triggering element has changed since we last called this method.
   * @return {Boolean}
   */
  EDRSurveyCode.Trigger.prototype.checkTriggerChanged = function () {
    var val = this._triggeringElementChanged;
    this._triggeringElementChanged = false;
    return val;
  };

  /**
   * The "manual" trigger - must be explicitly triggered by something external.
   * @constructor
   * @extends {EDRSurveyCode.Trigger}
   */
  EDRSurveyCode.TriggerManual = function () {
    EDRSurveyCode.Trigger.call(this, { type: 'manual' });
  };

  EDRUtility.inherits(EDRSurveyCode.TriggerManual, EDRSurveyCode.Trigger);

  /**
   * The "init" trigger.
   * @param {Object} config The trigger configuration.
   * @constructor
   * @extends {EDRSurveyCode.Trigger}
   */
  EDRSurveyCode.TriggerInit = function (config) {
    EDRSurveyCode.Trigger.call(this, config);

    /**
     * Will be set to true if the init trigger tries to fire, but is blocked by a condition.
     * This will cause it never to trigger again which the behaviour we want from an init trigger.
     * @type {Boolean}
     * @private
     */
    this._disabled = false;
  };

  EDRUtility.inherits(EDRSurveyCode.TriggerInit, EDRSurveyCode.Trigger);

  /**
   * Called periodically to check whether the page has "initialised".
   * @param {EDRSurveyCode.PageState}  pageState  The global state of the page, such as the time since the page was loaded, and the view count.
   * @param {EDRSurveyCode.Layer}      layer      The associated layer.
   */
  EDRSurveyCode.TriggerInit.prototype.evaluate = function (pageState, layer) {
    if (layer.everShownOnPage || this._disabled) {
      // not persisted, and already triggered at least once, or the layer is disabled - therefore this trigger has nothing to do
      return;
    }

    if (!this._doConditionsPass()) {
      EDRSurveyCode.Tester.log(17, this._config['type'], null, 4);
      this._disabled = true;
      return;
    }

    if (!this._config['delay']) {
      // no delay - trigger now
      this.setTriggered();
    } else if (
      pageState.getMillisecondsSincePageLoad() >
      parseInt(this._config['delay'], 10)
    ) {
      // delay complete - trigger now
      this.setTriggered();
    }
  };

  /**
   * A click trigger.
   * @param {Object} config The trigger configuration.
   * @constructor
   * @extends {EDRSurveyCode.Trigger}
   */
  EDRSurveyCode.TriggerClick = function (config) {
    EDRSurveyCode.Trigger.call(this, config);

    /**
     * True when we're waiting for a delay before triggering, false otherwise.
     * @type {Boolean}
     * @private
     */
    this._triggering = false;
  };
  EDRUtility.inherits(EDRSurveyCode.TriggerClick, EDRSurveyCode.Trigger);

  /**
   * Called when a window event fires - this class only cares about "clicks".
   * @param {Event}      e          The event instance.
   * @param {EDRSurveyCode.PageState}  pageState  The global state of the page, such as the time since the page was loaded, and the view count.
   * @param {EDRSurveyCode.Layer}      layer      The associated layer.
   */
  EDRSurveyCode.TriggerClick.prototype.onEvent = function (
    e,
    pageState,
    layer
  ) {
    // EDRSurveyCode.TriggerClick.superclass.onEvent.apply(this, arguments);

    if (e.type !== 'click' || !this._config['selector']) {
      // nothing to do
      return;
    }

    if (!EDRUtility.matchesSelector(e.target, this._config['selector'])) {
      // what has been clicked on does not match the selector - nothing to do
      return;
    }

    if (this._triggeringElement && e.target === this._triggeringElement) {
      // it's already triggered, and we've clicked on the same trigger again - untrigger
      this.setUntriggered();
    } else if (!this._triggering) {
      // check conditionals
      if (this._doConditionsPass(e.target)) {
        var that = this;
        var fn = function () {
          that._triggering = false;
          that.setTriggered(e.target);
        };

        if (this._config['delay'] && !layer.showing) {
          // layer not currently showing, and we have a delay set on this trigger
          this._triggering = true;
          setTimeout(fn, this._config['delay']);
        } else {
          fn();
        }
      } else {
        EDRSurveyCode.Tester.log(18, this._config['type'], e.target, 4);
      }
    }
  };

  /**
   * A scroll trigger.
   * @param {Object} config The trigger configuration.
   * @constructor
   * @extends {EDRSurveyCode.Trigger}
   */
  EDRSurveyCode.TriggerScroll = function (config) {
    this._repeatable = config['repeatable'] ? true : false;

    /**
     * An object to abstract away from the cross-browser complexities of working out how far the user has scrolled
     * the current document.
     * @type {Object}
     * @private
     */
    this._docScroll = {
      update: function () {
        var props = ['scrollTop', 'scrollLeft'];
        for (var i = 0; i < props.length; ++i) {
          if (document.body && document.body[props[i]]) {
            // IE5 or DTD 3.2
            this[props[i]] = document.body[props[i]];
          } else if (
            document.documentElement &&
            document.documentElement[props[i]]
          ) {
            // IE6 +4.01 and user has scrolled
            this[props[i]] = document.documentElement[props[i]];
          } else if (
            document.documentElement &&
            !document.documentElement[props[i]]
          ) {
            // IE6 +4.01 but no scrolling going on
            this[props[i]] = 0;
          }
        }
      },
    };

    EDRSurveyCode.Trigger.call(this, config);
  };
  EDRUtility.inherits(EDRSurveyCode.TriggerScroll, EDRSurveyCode.Trigger);

  /**
   * Called periodically to check whether the observed elements have been scrolled.
   * @param {EDRSurveyCode.PageState}  pageState  The global state of the page, such as the time since the page was loaded, and the view count.
   * @param {EDRSurveyCode.Layer}      layer      The associated layer.
   */
  EDRSurveyCode.TriggerScroll.prototype.evaluate = function (pageState, layer) {
    var e = this._config['element'];
    if (!e) {
      return;
    }

    var ax = this._config['axes'];

    if (ax) {
      var axisMap = {
        x: 'scrollLeft',
        y: 'scrollTop',
      };

      var any = false;

      var fnValToPixels = function (e, axis, val) {
        var ret = parseInt(val, 10);
        if (val.indexOf('%') !== -1) {
          var total = axis === 'y' ? e.offsetHeight : e.offsetWidth;
          ret = total * (ret / 100);
        }
        return ret;
      };

      var es = [];
      var heightElement = null;
      if (e === 'document') {
        this._docScroll.update();
        es = [this._docScroll];
        heightElement = window.document.body;
      } else {
        es = document.querySelectorAll(e);
      }

      for (var i = 0; i < es.length; ++i) {
        for (var a in axisMap) {
          if (axisMap.hasOwnProperty(a)) {
            if (ax[a]) {
              // check lower bound

              var lb = ax[a]['lowerBound'];
              var ub = ax[a]['upperBound'];

              var mlb =
                lb === null ||
                lb === undefined ||
                es[i][axisMap[a]] >=
                  fnValToPixels(heightElement ? heightElement : es[i], a, lb);
              var mub =
                ub === null ||
                ub === undefined ||
                es[i][axisMap[a]] <=
                  fnValToPixels(heightElement ? heightElement : es[i], a, ub);

              if (mlb && mub) {
                if (this._repeatable || !layer.everShownOnPage) {
                  // if this trigger can repeat or if it's the first time the layer has been shown - trigger it
                  this.setTriggered(
                    e === 'document' ? window.document.body : es[i]
                  );
                }
                any = true;
              }
            }
          }
        }
      }

      if (!any) {
        this.setUntriggered();
      }
    }
  };

  /**
   * Base class which looks after a layer deployment conditional.
   *
   * @param {Object}  config  The configuration of this conditional.
   * @constructor
   */
  EDRSurveyCode.Conditional = function (config) {
    /**
     * The conditional match mode - what this actually means is specific to the concrete conditional implementation.
     * @type {Number}
     * @private
     */
    this._matchMode = config['matchMode'];

    /**
     * True if the conditional state evaluation should be negated, false otherwise.
     * @type {Boolean}
     * @private
     */
    this._negate = config['not'] === true;

    /**
     * The value that the specific conditional will test against.
     * @type {Boolean}
     * @private
     */
    this._value = config['value'];
  };

  /**
   * Create a new conditional from conditional deployment configuration.
   *
   * @static
   * @param {Object} config The conditional configuration.
   * @return {EDRSurveyCode.Conditional}
   */
  EDRSurveyCode.Conditional.create = function (config) {
    switch (config['type']) {
      case 'cookie':
        return new EDRSurveyCode.ConditionalCookie(config);
      case 'element':
        return new EDRSurveyCode.ConditionalElement(config);
      case 'triggerElement':
        return new EDRSurveyCode.ConditionalTriggerElement(config);
      default:
        EDRSurveyCode.Tester.log(19, null, [config['type']], 2);
    }
  };

  /**
   * Returns true if the conditional currently evaluates to true after applying _negate
   * @param {Element=}  triggerElement  (Optional) - will be specified for evaluating trigger conditionals, null for global conditionals.
   * @public
   * @return {Boolean}
   */
  EDRSurveyCode.Conditional.prototype.isTrue = function (triggerElement) {
    var ret = this._evaluate(triggerElement);
    ret = this._negate ? !ret : ret;
    return ret;
  };

  /**
   * Returns true if the conditional currently evaluates to true.
   * @param {Element=}  triggerElement  (Optional) - will be specified for evaluating trigger conditionals, null for global conditionals.
   * @private
   * @return {Boolean}
   */
  EDRSurveyCode.Conditional.prototype._evaluate = function (triggerElement) {
    /* no implementation */
    return true;
  };

  /**
   * A conditional which is true if a cookie has, or does not have (negated), a certain value.
   *
   * @param {Object}  config  The conditional configuration.
   * @constructor
   * @extends {EDRSurveyCode.Conditional}
   */
  EDRSurveyCode.ConditionalCookie = function (config) {
    EDRSurveyCode.Conditional.call(this, config);
  };
  EDRUtility.inherits(
    EDRSurveyCode.ConditionalCookie,
    EDRSurveyCode.Conditional
  );

  /**
   * Returns true if there is a cookie matching the name in "value".
   *
   * @param {Element=}  triggerElement  (Optional) - will be specified for evaluating trigger conditionals, null for global conditionals.
   * @return {Boolean}
   */
  EDRSurveyCode.ConditionalCookie.prototype._evaluate = function (
    triggerElement
  ) {
    return EDRUtility.readCookie(this._value) !== null;
  };

  /**
   * A conditional which is true if an element exists, or does not exist (when negated).
   *
   * @param {Object}  config  The conditional configuration.
   * @constructor
   * @extends {EDRSurveyCode.Conditional}
   */
  EDRSurveyCode.ConditionalElement = function (config) {
    EDRSurveyCode.Conditional.call(this, config);
  };
  EDRUtility.inherits(
    EDRSurveyCode.ConditionalElement,
    EDRSurveyCode.Conditional
  );

  /**
   * Returns true if there is an element matching the css selector in "value".
   * @param {Element=}  triggerElement  (Optional) - will be specified for evaluating trigger conditionals, null for global conditionals.
   * @return {Boolean}
   */
  EDRSurveyCode.ConditionalElement.prototype._evaluate = function (
    triggerElement
  ) {
    var mm = this._matchMode; // 0 = exists, 1 = visible
    var e = document.querySelector(this._value);

    if (e && mm === 1) {
      // check for visibility
      return e.offsetWidth !== 0;
    } else {
      // check if the element simply exists
      return e !== null;
    }
  };

  /**
   * A conditional which is true if an the trigger element matches the css selector in value, false otherwise.
   *
   * @param {Object}  config  The conditional configuration.
   * @constructor
   * @extends {EDRSurveyCode.Conditional}
   */
  EDRSurveyCode.ConditionalTriggerElement = function (config) {
    EDRSurveyCode.Conditional.call(this, config);
  };
  EDRUtility.inherits(
    EDRSurveyCode.ConditionalTriggerElement,
    EDRSurveyCode.Conditional
  );

  /**
   * Returns true if there is an element matching the css selector in "value".
   * @param {Element=}  triggerElement  (Optional) - will be specified for evaluating trigger conditionals, null for global conditionals.
   * @return {Boolean}
   */
  EDRSurveyCode.ConditionalTriggerElement.prototype._evaluate = function (
    triggerElement
  ) {
    if (!triggerElement) {
      return false;
    }
    return EDRUtility.matchesSelector(triggerElement, this._value);
  };

  /**
   * Class which positions a layer relative to the window, document or dom element.
   * @constructor
   */
  EDRSurveyCode.PositionedLayer = function (
    layer,
    anchor,
    iframeStyles,
    layout
  ) {
    // private members

    /**
     * The layer to position.
     * @type {EDRSurveyCode.Layer}
     * @private
     */
    this._layer = layer;

    /**
     * The anchor dom element which this layer will be anchored to.
     * @type {Element}
     * @private
     */
    this._anchor = anchor;

    /**
     * The explicit styles of the iframe, ultimately from the provider.
     * @type {Object.<string, string>}
     * @private
     */
    this._iframeStyles = iframeStyles;

    /**
     * The css-styles for x - y (either percentages or pixels), which are always positive (the absolute value of the original style).
     * @type {Object.<string, string>}
     * @private
     */
    this._offsetsAbsStyles = {};

    /**
     * The numeric value for the percentage offsets for x and y - can be used when performing maths as they're not strings.
     * @type {Object.<string, number>}
     * @private
     */
    this._offsetsPercentages = {};

    /**
     * The numeric pixel values for offsets x and y - can be used when performing maths as they're not strings.
     * @type {Object.<string, number>}
     * @private
     */
    this._offsets = {};

    this._offsetsNegated = {};

    /**
     * An object containing the object CSS styles for both x and y axes.
     * @type {Object.<string, string>}
     * @private
     */
    this._offsetStyles = {};

    /**
     * Keeps track of the current edges of the layer which are anchored to the element.
     * @type {Array.<string>}
     * @private
     */
    this._currentLayerAnchorEdges = null;

    /**
     * A collection of "click capturers" which are used to intercept the request to "execute" the layer.
     * It's done in this strange way to ensure that all clicks on the layer (which may open a popup window) happen
     * in the context of the clients' website, and happen as a direct result of the click, in order to stop popup
     * blockers preventing any popup window that may need to be opened.
     * @type {Object.<string, element>}
     * @private
     */
    this._goClickCapturers = {};

    // normalise offset specification from the layout config and initialise the members which are used for various layout methods
    var v = layout['position']['offset'] || {},
      it = { x: 0, y: 0 };
    for (var d in it) {
      if (it.hasOwnProperty(d)) {
        var s = v[d]
            ? typeof v[d] === 'string'
              ? v[d]
              : v[d].toString()
            : '0',
          isP = s.indexOf('%') !== -1,
          i = parseInt(s, 10);
        this._offsets[d] = isP ? 0 : i;
        this._offsetsPercentages[d] = isP ? i : 0;
        this._offsetsNegated[d] = i < 0;
        this._offsetsAbsStyles[d] = Math.abs(i) + (isP ? '%;' : 'px;');
        this._offsetStyles[d] = i + (isP ? '%;' : 'px;');
      }
    }

    /**
     * An object containing the anchor edges for both the anchor and the layer. Can be null/undefined for auto placement.
     * @type {Object.<string, string>}
     * @private
     */
    this._edges = layout['position']['edges'];

    /**
     * The setTimeout() ID.
     * @private
     */
    this._timeout = null;

    this._position();
  };

  /**
   * Returns true if the passed element is a click capturer registered with this positioner.
   * @param {Element}  e  The element to test.
   * @return {Boolean}
   */
  EDRSurveyCode.PositionedLayer.prototype.isClickCapturer = function (e) {
    var cc = this._goClickCapturers;
    for (var id in cc) {
      if (cc.hasOwnProperty(id)) {
        if (e === cc[id]) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Focuses the first "click capturer" (execute button).
   */
  EDRSurveyCode.PositionedLayer.prototype.focusFirstClickCapturer =
    function () {
      for (var ccId in this._goClickCapturers) {
        if (this._goClickCapturers.hasOwnProperty(ccId)) {
          this._goClickCapturers[ccId].focus();
          break;
        }
      }
    };

  EDRSurveyCode.PositionedLayer.prototype.setMetrics = function (
    size,
    laterRects
  ) {
    this._positionWithSize(size);
    // ensure the all the go click capturer anchors are positioned in the correct place
    this._doGoClickCapturers(laterRects);
  };

  EDRSurveyCode.PositionedLayer.prototype._position = function () {
    var that = this;

    // only call _positionWithSize as the result of a callback from the layer into setMetrics() (this stops flickering as it resizes)
    this._layer.requestCurrentMetrics();

    // The anchor could move, the layer could dynamically create or delete it's "go" buttons, the window could resize
    // meaning that a responsive-width layer could also resize.
    // All of the above reasons mean that we need to keep calling _position over and over to ensure that the
    // positioning logic keeps up with the changes.
    this._again();
  };

  EDRSurveyCode.PositionedLayer.prototype._positionWithSize = function (
    layerSize
  ) {
    if (!this._anchor) {
      return;
    }

    // if the trigger is no longer visible, hide the layer
    if (this._anchor.offsetWidth === 0) {
      this._layer.forceHide();
      return;
    }

    var css = '';

    // if there is no explicit width and/or height style set for the iframe in css, set it to the size
    // of the layer's content.
    if (!this._iframeStyles['width'] || this._iframeStyles['width'] == '100%') {
      css += 'width:' + layerSize.x + 'px;';
    }
    if (!this._iframeStyles['height']) {
      css += 'height:' + layerSize.y + 'px;';
    }

    switch (this._anchor) {
      case window:
      case window.document:
        css += this._positionToWindow(layerSize);
        break;

      default:
        if (this._edges) {
          // position by edges
          css += this._positionByEdges(
            layerSize,
            this._edges,
            this._offsetStyles
          );
        } else {
          // position automatically
          css += this._positionAutomatically(layerSize);
        }
    }

    EDRUtility.setStyleAttribute(this._layer.div, css);
  };

  EDRSurveyCode.PositionedLayer.prototype._positionToWindow = function (
    layerSize
  ) {
    var edges = this._edges,
      has = function (edge) {
        return edges ? EDRUtility.arrayIndexOf(edges, edge) !== -1 : false;
      },
      space = EDRUtility.getViewportSize(),
      allowFixed = this._anchor === window && layerSize.y < space.y;

    var css = 'position:' + (allowFixed ? 'fixed' : 'absolute') + ';';

    if (has('left') === has('right')) {
      // centre horizontally
      var ox = this._offsets.x - Math.min(space.x / 2, layerSize.x / 2);
      css +=
        'right:auto;' +
        'left:' +
        (this._offsetsPercentages.x + 50) +
        '%;' +
        'margin-left:' +
        ox +
        'px;';
    } else if (has('left')) {
      css += 'right:auto;left:' + this._offsetStyles.x;
    } else if (has('right')) {
      css +=
        'left:auto;right:' +
        (this._offsetsNegated.x ? '' : '-') +
        this._offsetsAbsStyles.x;
    }
    if (has('top') === has('bottom')) {
      // centre vertically
      var oy = this._offsets.y - Math.min(space.y / 2, layerSize.y / 2);
      css +=
        'bottom:auto;top:' +
        (this._offsetsPercentages.y + 50) +
        '%;' +
        'margin-top:' +
        oy +
        'px;';
    } else if (has('top')) {
      css += 'bottom:auto;top:' + this._offsetStyles.y;
    } else if (has('bottom')) {
      css +=
        'top:auto;bottom:' +
        (this._offsetsNegated.y ? '' : '-') +
        this._offsetsAbsStyles.y;
    }

    this._layerSetAnchorEdges([]);

    return css;
  };

  EDRSurveyCode.PositionedLayer.prototype._again = function () {
    clearTimeout(this._timeout);
    var that = this;
    this._timeout = setTimeout(function () {
      that._position();
    }, 1000);
  };

  EDRSurveyCode.PositionedLayer.prototype._positionAutomatically = function (
    layerSize
  ) {
    var trigPos = EDRUtility.findFixedPos(this._anchor);
    var trigSize = {
      x: this._anchor.offsetWidth,
      y: this._anchor.offsetHeight,
    };

    // automatically work out the best edges in which to anchor the layer
    var space = EDRUtility.getViewportSize();

    // work out how much space is available around the trigger
    var sides = {
      left: trigPos.x,
      right: space.x - (trigPos.x + trigSize.x),
      bottom: space.y - (trigPos.y + trigSize.y),
      top: trigPos.y,
    };

    // pick the best edge of the trigger to anchor the layer to
    var anchor;
    if (sides['left'] > layerSize.x || sides['right'] > layerSize.x) {
      // the left and/or right sides have enough space - big the largest
      anchor = sides['left'] > sides['right'] ? 'left' : 'right';
    } else {
      // not enough space on the left or right - pick the top of bottom depending on which has the most space
      anchor = sides['top'] > sides['bottom'] ? 'top' : 'bottom';
    }

    // pick the opposite edge for the layer anchor
    var opp = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    };
    var layer = opp[anchor];

    var fnNegateStyle = function (style) {
      if (style.length > 0 && style[0] == '-') {
        return style.substring(1);
      } else {
        return '-' + style;
      }
    };

    // When auto-positioning, the layer offsets work differently than in other positioning modes.
    // For example, when anchoring on horizontal planes (left or right), only the x offset has any affect, and similarly
    // when anchoring on vertical planes (top or bottom), on the y offset has any affect.
    // In addition, the layer offsets are normalised to the "top, right" anchor edge, if we're auto-positioning on
    // opposite edges we need to negate the relevant offset style.
    var os = this._offsetStyles;
    var offsetStyles = {
      x:
        anchor === 'right' ? os.x : anchor === 'left' ? fnNegateStyle(os.x) : 0,
      y:
        anchor === 'top' ? os.y : anchor === 'bottom' ? fnNegateStyle(os.y) : 0,
    };

    return this._positionByEdges(
      layerSize,
      { anchor: [anchor], layer: [layer] },
      offsetStyles
    );
  };

  EDRSurveyCode.PositionedLayer.prototype._positionByEdges = function (
    layerSize,
    edges,
    offsetStyles
  ) {
    var getLocal = function (ele, edges, size) {
      var has = function (edge) {
        return edges ? EDRUtility.arrayIndexOf(edges, edge) !== -1 : false;
      };
      var size = size || { x: ele.clientWidth, y: ele.clientHeight };
      var ret = { x: size.x / 2, y: size.y / 2 };
      if (has('top')) {
        ret.y = 0;
      }
      if (has('bottom')) {
        ret.y = size.y;
      }
      if (has('left')) {
        ret.x = 0;
      }
      if (has('right')) {
        ret.x = size.x;
      }
      return ret;
    };

    var pos = EDRUtility.findFixedPos(this._anchor);

    // get local coordinates of the edge points for both the anchor and layer
    var lAnchor = getLocal(this._anchor, edges['anchor']);
    var lLayer = getLocal(this._layer.div, edges['layer'], layerSize);

    // get document coordinates for the edge points for the anchor
    var dAnchor = { x: pos.x + lAnchor.x, y: pos.y + lAnchor.y };

    // subtract the local coordinates for the layer
    var absolute = { x: dAnchor.x - lLayer.x, y: dAnchor.y - lLayer.y };

    // constrain based upon available space
    var space = EDRUtility.getViewportSize();
    var clamp = function (val, min, max) {
      return val < min ? min : val > max ? max : val;
    };
    absolute.x = clamp(absolute.x, 0, space.x - layerSize.x);
    absolute.y = clamp(absolute.y, 0, space.y - layerSize.y);

    var css =
      'position:fixed;' +
      'left:' +
      absolute.x +
      'px;' +
      'top:' +
      absolute.y +
      'px;' +
      'margin-left:' +
      offsetStyles.x +
      'margin-top:' +
      offsetStyles.y;

    this._layerSetAnchorEdges(edges['layer']);

    return css;
  };

  EDRSurveyCode.PositionedLayer.prototype._layerSetAnchorEdges = function (
    edges
  ) {
    // have the edges changed since last time this method was called?
    var changed =
      this._currentLayerAnchorEdges === null ||
      this._currentLayerAnchorEdges.length !== edges.length;
    if (!changed) {
      for (var i = 0; i < edges.length; ++i) {
        if (
          EDRUtility.arrayIndexOf(this._currentLayerAnchorEdges, edges[i]) == -1
        ) {
          changed = true;
          break;
        }
      }
    }

    if (changed) {
      // if the anchored edges have changed - need to tell the layer about it so it can optionally update it's styles
      this._currentLayerAnchorEdges = edges;
      this._layer.onAnchorEdgesChanged(edges);
    }
  };

  EDRSurveyCode.PositionedLayer.prototype._doGoClickCapturers = function (
    laterRects
  ) {
    for (var id in laterRects) {
      if (laterRects.hasOwnProperty(id)) {
        var rect = laterRects[id];

        var a = document.getElementById(id);
        if (!a) {
          var textSuffix = '';
          delete this._goClickCapturers[id];
          a = document.createElement('a');
          a.id = id;
          a.href = 'javascript:void(0)';
          a.className = EDRSurveyCode.Main.GO_CLICK_CAPTURER_CLASS;
          if (rect.title) {
            a.title = rect.title;
            textSuffix = ' - ' + rect.title;
          }
          if (rect.text) {
            if (typeof a.textContent !== 'undefined') {
              a.textContent = rect.text + textSuffix;
            } else {
              a.innerText = rect.text + textSuffix;
            }
          }
          this._layer.div.insertBefore(a, this._layer.div.firstChild);
          this._goClickCapturers[id] = a;
        }

        // put the a in the right place
        EDRUtility.setStyleAttribute(
          a,
          'top:' +
            rect.top +
            'px;left:' +
            rect.left +
            'px;width:' +
            rect.width +
            'px;height:' +
            rect.height +
            'px;'
        );
      }
    }
  };

  EDRSurveyCode.PositionedLayer.prototype.destroy = function () {
    clearTimeout(this._timeout);
    this._timeout = null;
    this._anchor = null;
    this._layer = null;

    for (var id in this._goClickCapturers) {
      if (this._goClickCapturers.hasOwnProperty(id)) {
        var e = document.getElementById(id);
        if (e && e.parentNode) {
          e.parentNode.removeChild(e);
        }
      }
    }

    this._goClickCapturers = {};
  };

  /**
   * Generates a unique identifier and stores it in a cookie, which expires in days.
   *
   * @param {Number} days The number of days the cookie is valid for.
   *
   * @private
   */
  EDRSurveyCode._generateAndSetId = function (days) {
    var uuid = EDRUtility.genGuid();
    EDRSurveyCode.setId(uuid, days);
    return uuid;
  };

  /**
   * Get the UUID if already generated, else generates a new UUID and returns it.
   *
   * @param days  The number of days to track for.
   *
   * @returns {String}
   */
  EDRSurveyCode.getId = function (days) {
    var uuid = EDRSurveyCode._getId();

    days = typeof days === 'undefined' ? EDRSurvey.TRACKING_DAYS : days;

    if (null === uuid && days != EDRSurveyCode.TRACKING_DAYS_DISABLED) {
      uuid = EDRSurveyCode._generateAndSetId(days);
    }

    return uuid;
  };

  /**
   * Sets the id visitor in the tracking cookie.
   *
   * @param {String} id
   *
   * @param {Number} days
   */
  EDRSurveyCode.setId = function (id, days) {
    if (typeof days === 'undefined' || isNaN(days)) {
      throw 'Days not defined';
    }

    if (days < 0) {
      throw 'Days smaller than 0';
    }

    EDRUtility.createCookie(
      EDRSurveyCode.TRACKING_COOKIE_NAME,
      id,
      days,
      EDRSurveyCode.Main.DOMAIN
    );
    EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.TRACKING_ID_SET, id);
  };

  /**
   * Get the UUID if already generated.
   *
   * @returns {String}
   *
   * @private
   */
  EDRSurveyCode._getId = function () {
    return EDRUtility.readCookie(EDRSurveyCode.TRACKING_COOKIE_NAME);
  };

  /**
   * @class EDRSurveyCode.Tester
   * Manages a console log div anchored to the bottom of the page when test mode is switched on.
   */
  EDRSurveyCode.Tester = {
    MAX_ITEMS_WHEN_NOT_IN_TEST_MODE: 100,

    /**
     * Whether the server has sent us the test message strings yet.
     * @param {Boolean}
     */
    receivedStrings: false,

    /**
     * The DOM element container for the debugging controls, if debugging is activated.
     * @type {Element}
     */
    _testPanel: null,

    /**
     * The DOM element container for the log.
     * @type {Element}
     */
    _logPanel: null,

    /**
     * A set of messages codes and their strings.
     * @param {Object.<string, string>}
     */
    _messageStrings: {},

    _logItems: [],

    activate: function (messageStrings) {
      if (messageStrings) {
        this._messageStrings = messageStrings;
        this.receivedStrings = true;
      }

      if (!this._testPanel) {
        var style = document.createElement('style');
        style.type = 'text/css';
        EDRUtility.setStyleBlock(
          style,
          '#edr_test {font-size:12px;text-align:left;font-family:monospace;position:fixed;bottom:0;left:0;right:0;height:15%;z-index:' +
            EDRSurveyCode.Main.ZINDEX_ON_TOP +
            ';' +
            'background-color:#fff;border-top:1px solid silver;overflow:auto;color:black;}' +
            '#edr_test ul, #edr_debug_panel li {padding:0;margin:0;list-style-type:none;}' +
            '#edr_test li span {margin-right:1em;}' +
            '#edr_test li span.id {font-weight:bold; min-width: 10em;display:inline-block;}' +
            '#edr_test li.t_3 {color:red;}' +
            '#edr_test li.t_4 {color:goldenrod;}' +
            '#edr_test li.s_lcl {background-color:#E9E9FF;}' +
            '#edr_test li.s_prv {background-color:#FFF7E6;}' +
            '#edr_test li.s_svr {background-color:#F7E6FF;}' +
            '#edr_test li.t_2 {color:white;background-color:red;}' +
            '.' +
            EDRSurveyCode.Main.GO_CLICK_CAPTURER_CLASS +
            ' {' +
            'opacity:0.5;display:block;filter: alpha(opacity = 50);' +
            'background-color:white;outline:1px solid fuchsia;' +
            '}'
        );
        var head = document.getElementsByTagName('head')[0];
        head.appendChild(style);

        // create the test panel
        var debugPanel = (this._testPanel = document.createElement('div'));
        debugPanel.id = 'edr_test';
        EDRSurveyCode.Main._domContainer.appendChild(debugPanel);

        this._logPanel = document.createElement('ul');
        debugPanel.appendChild(this._logPanel);
      }

      this._refresh();
    },

    /**
     * Returns true if the tester is active, false otherwise.
     * @return {Boolean}
     */
    isActive: function () {
      return this._testPanel !== null;
    },

    _refresh: function () {
      while (this._logPanel.childNodes.length >= 1) {
        this._logPanel.removeChild(this._logPanel.firstChild);
      }

      for (var i = 0; i < this._logItems.length; ++i) {
        this._logItem(this._logItems[i]);
      }
    },

    _logItem: function (item) {
      var toStr = function (o) {
        if (o === null) {
          return 'null';
        }
        if (o === undefined) {
          return 'undefined';
        }
        if (o.nodeName) {
          return 'Node: ' + o.nodeName;
        }
        return o.toString();
      };

      var li = document.createElement('li');

      var strs = {},
        i;

      switch (item.src) {
        case 0: // local
          strs['src'] = 'lcl';
          break;
        case 1: // server
          strs['src'] = 'svr';
          break;
        case 2:
          strs['src'] = 'prv';
      }
      li.className = 't_' + item.type + ' ' + ('s_' + strs['src']);

      strs['dt'] = item.dt.toLocaleTimeString();

      if (item.id) {
        strs['id'] = item.id;
      }

      if (item.msg) {
        strs['msg'] = item.msg;
      } else {
        var p = item.p instanceof Array ? item.p : [item.p];

        var messageTemplate = this._messageStrings[item.c];
        if (messageTemplate) {
          for (i = 0; i < p.length; ++i) {
            messageTemplate = messageTemplate.replace(
              '{' + i + '}',
              toStr(p[i])
            );
          }
          strs['msg'] = messageTemplate;
        } else {
          strs['code'] = item.c;
          for (i = 0; i < p.length; ++i) {
            strs['par_' + i] = toStr(p[i]);
          }
        }
      }

      for (var str in strs) {
        if (strs.hasOwnProperty(str)) {
          var span = document.createElement('span');
          span.className = str;
          span.innerText = span.textContent = strs[str];
          li.appendChild(span);
        }
      }

      this._logPanel.insertBefore(li, this._logPanel.firstChild);
    },

    log: function (code, id, params, type) {
      if (type === undefined) {
        type = 6; // default: 6 == INFO - see Zend_Log
      }

      this._rawLogItem({
        dt: new Date(),
        src: 0,
        type: type,
        id: id,
        c: code,
        p: params,
      });
    },

    providerLog: function (id, msg, type) {
      if (type === undefined) {
        type = 6; // default: 6 == INFO - see Zend_Log
      }

      this._rawLogItem({
        dt: new Date(),
        src: 2,
        type: type,
        id: id,
        msg: msg,
      });
    },

    serverLog: function (id, serverLogItem) {
      this._rawLogItem({
        dt: new Date(serverLogItem['dt'] * 1000),
        src: 1,
        type: serverLogItem['type'],
        id: id,
        msg: serverLogItem['msg'],
      });
    },

    _rawLogItem: function (item) {
      this._logItems.push(item);
      if (this._logPanel) {
        this._logItem(item);
      } else if (this._logItems.length > this.MAX_ITEMS_WHEN_NOT_IN_TEST_MODE) {
        // stop memory leaks when not running in test mode
        this._logItems.pop();
      }
    },
  };

  EDRSurveyCode.EventHub = {
    BEFORE_INITIALISED: 'beforeInitialised',
    INITIALISED: 'initialised',

    LAYER_INITIALISED: 'layerInitialised',
    LAYER_CAN_SHOW: 'layerCanShow',
    LAYER_WONT_SHOW: 'layerWontShow',
    LAYER_SHOWN: 'layerShown',
    LAYER_HIDDEN: 'layerHidden',
    LAYER_DESTROYED: 'layerDestroyed',
    LAYER_EXECUTED: 'layerExecuted',
    LAYER_INTERACTED: 'layerInteracted',
    TRACKING_ID_SET: 'trackingIdSet',

    _handlers: [],

    attach: function (event, handler) {
      if (typeof handler !== 'function') {
        return false;
      }

      var idx = this._find(event, handler);
      if (idx !== -1) {
        return false;
      }

      this._handlers.push({ e: event, h: handler });

      return true;
    },

    detach: function (event, handler) {
      var idx = this._find(event, handler);
      if (idx === -1) {
        return false;
      }

      // reindex array without removed element
      var rest = this._handlers.slice(idx + 1 || this._handlers.length);
      this._handlers.length = idx < 0 ? this._handlers.length + idx : idx;
      this._handlers.push.apply(this._handlers, rest);

      return true;
    },

    raise: function (event, data) {
      for (var i = 0; i < this._handlers.length; ++i) {
        var h = this._handlers[i];
        if (h.e === event) {
          try {
            h.h.call(global, data || null);
          } catch (e) {
            /* Catch and squelch all exceptions as otherwise we'll stop calling further handlers if a
             * handler throws an exception. Note: this masks the actual exception. */
          }
        }
      }
    },

    _find: function (event, handler) {
      for (var i = 0; i < this._handlers.length; ++i) {
        var h = this._handlers[i];
        if (h.e === event && h.h === handler) {
          return i;
        }
      }
      return -1;
    },
  };

  /**
   * @class EDRSurveyCode.Main
   * Singleton class which manages all eDR code on a client's website.
   */
  EDRSurveyCode.Main = {
    // constants
    REMOTE_HOST: null,
    REMOTE_PATH: null,
    ID: null,
    LOCALE: null,
    DATA1: null,
    DATA2: null,
    DATA3: null,
    TEST: false,
    VERSION: null,
    PROPORTION: null,
    DOMAIN: '',

    /**
     * A z-index value which is sufficiently high to ensure that we
     * go over the top of all the rest of the hosting site.
     * @const
     * @type {Number}
     */
    ZINDEX_ON_TOP: 999999,

    /**
     * The css class given to the "go click capturers" - used to intercept the click/input to execute the layer.
     * @const
     * @type {String}
     */
    GO_CLICK_CAPTURER_CLASS: 'edr_go',

    /**
     * The css class given to the layer iframes - the layer content appears within these iframes.
     * @const
     * @type {String}
     */
    LAYER_IFRAME_CLASS: 'edr_layer',

    /**
     * The prefix given to the layerConfig's cookie name.
     *
     * Used by the request handler to force a configuration to be used for the layer (used with rootDeploymentId too).
     *
     * @type {string}
     * @private
     */
    COOKIE_FORCED_CONFIG_PREFIX: 'eds_fcfg_',

    // public members
    eDRUrlBase: null,
    layers: {},

    /**
     * Used only under IE and only when document.domain has been explicitly set, this member will be set to a URL
     * which points to a document on the client's website which also explicitly sets document.domain.
     * This will be the current document unless it's explicitly overridden by a specific install by calling
     * EDRSurveyCode.Main.holdingWindowAccessDocument = 'xxxxx';
     * This "access document" is used when holding page functionality is required so that the survey code can get
     * access to the popup window's DOM.
     * @type {String}
     */
    holdingWindowAccessDocument: window.location,

    /**
     * Probe values which have been manually pushed to us using either the setProbeValues() or setProbeValue() methods.
     * @type {Object.<String,(Boolean|String|Number)>}
     */
    pushedProbeValues: {},

    // private members

    /**
     * Container member for all our DOM elements.
     * @private
     */
    _domContainer: null,

    /**
     * True if first-party cookies are enabled, false if not.
     * @type {Boolean}
     * @private
     */
    _cookiesEnabled: false,

    /**
     * Set to the locally installed version of Adobe Flash, or NULL if flash is not installed.
     * @type {String}
     * @private
     */
    _flashVersion: null,

    /**
     * True if the browser supports all the features that this survey code needs, false otherwise.
     * @type {Boolean}
     * @private
     */
    _platformSupported: null,

    /**
     * True if the survey code has been initialised.
     * @type {Boolean}
     * @private
     */
    _initialised: false,

    /**
     * When the first lightbox is shown, focusable elements have their tabindex set to -1 so only layer content can be tabbed to.
     * This member holds the old tabindexes so they can be restored when the last lightbox hides.
     * @type {Array.<Object.<string, mixed>>}
     * @private
     */
    _savedElementTabIndices: [],

    /**
     * Contains an array of layer IDs which are currently displaying lightboxes.
     * @type {Array.<String>}
     * @private
     */
    _layersWithLightboxes: [],

    /**
     * Main function. Entry-point for application.
     */
    main: function () {
      EDRSurveyCode.Tester.log(1);

      // sort out the domain
      var dd = document.domain;
      if (!this.DOMAIN) {
        // use the document domain
        this.DOMAIN = dd;
      } else {
        // validate DOMAIN
        if (dd.indexOf(this.DOMAIN, dd.length - this.DOMAIN.length) === -1) {
          EDRSurveyCode.Tester.log(29, null, this.DOMAIN, 3);
          this.DOMAIN = dd;
        }
      }

      EDRSurveyCode.Tester.log(28, null, this.DOMAIN);

      // start ticking
      this._tick();
      var that = this;
      setInterval(function () {
        that._tick();
      }, 500);

      // wait for the document to before ready before kicking off the main survey code
      this._waitForReady(1);
    },

    _waitForReady: function (attempt) {
      EDRSurveyCode.Tester.log(2, null, attempt);

      // wait for DOM ready...
      var that = this;
      /in/.test(document.readyState)
        ? setTimeout(function () {
            that._waitForReady(attempt + 1);
          }, 9)
        : this._checkBeforeInitialised(1);
    },

    _checkBeforeInitialised: function (attempt) {
      var evt = { wait: false, abort: false };
      EDRSurveyCode.EventHub.raise(
        EDRSurveyCode.EventHub.BEFORE_INITIALISED,
        evt
      );

      if (evt['abort']) {
        // abort startup - no point in logging this as the logger won't be created if code execution stops here
        return;
      }

      if (evt['wait']) {
        var that = this;
        EDRSurveyCode.Tester.log(30, null, attempt);
        // keep calling the event to see whether the survey code can start up.
        // The delay between each successive calls to beforeInitialised increases by a half-second on each attempt
        setTimeout(function () {
          that._checkBeforeInitialised(attempt + 1);
        }, 100 + (attempt - 1) * 500);
      } else {
        this._start();
      }
    },

    _start: function () {
      EDRSurveyCode.Tester.log(3);

      // warn if legacy survey code (< v7.0.0.0) is also installed
      var legacySid = window['ecos_sid'];
      var legacyVault = window['ecos_vault'];
      if (legacySid && legacyVault) {
        EDRSurveyCode.Tester.log(
          32,
          null,
          'ESV-' + (legacyVault !== '_' ? legacyVault + '-' : '') + legacySid,
          4
        );
      }

      // initialise the page state
      EDRSurveyCode.PageState.init();

      this._cookiesEnabled = EDRUtility.areCookiesEnabled();
      this._flashVersion = EDRUtility.getFlashVersion();
      this._platformSupported = this._testIfPlatformSupported(
        this._cookiesEnabled,
        this._flashVersion
      );

      EDRSurveyCode.Tester.log(
        4,
        null,
        [this._cookiesEnabled, this._flashVersion, this._platformSupported],
        this._platformSupported ? 6 : 2
      );

      this.eDRUrlBase =
        (document.location.protocol === 'https:' ? 'https://' : 'http://') +
        this.REMOTE_HOST +
        this.REMOTE_PATH;

      // create global survey styles
      var head = document.getElementsByTagName('head')[0];
      var style = document.createElement('style');
      style.type = 'text/css';
      head.appendChild(style);
      EDRUtility.setStyleBlock(
        style,
        '#edr_survey .edr_lwrap iframe {' +
          'width:100%;' +
          'height:100%;' +
          '}' +
          '#edr_survey .' +
          this.GO_CLICK_CAPTURER_CLASS +
          ' {' +
          'opacity:0;display:block;filter:alpha(opacity = 0);position:absolute;font-size:0;' +
          'background-color:#000;margin:0;padding:0;visibility:visible;z-index:' +
          (this.ZINDEX_ON_TOP + 1) +
          ';' +
          '}' +
          '#edr_survey .edr_lb {' +
          'position:fixed;top:0;left:0;right:0;bottom:0;z-index:' +
          (this.ZINDEX_ON_TOP - 1) +
          ';display:none;' +
          '}'
      );

      // create a container div for all eDR DOM elements
      var container = (this._domContainer = document.createElement('div'));
      container.id = 'edr_survey';

      document.body.insertBefore(container, document.body.firstChild);

      if (this.TEST) {
        EDRSurveyCode.Tester.activate();
      }

      // attach to global events for this document
      this._attachDocumentEvents(document);

      EDRSurveyCode.EventHub.raise(EDRSurveyCode.EventHub.INITIALISED, {
        supportedPlatform: this._platformSupported,
      });
      this._initialised = true;

      // should we create the initial deployment?

      if (Math.random() < this.PROPORTION) {
        // This page refresh passed the check - deploy initial layer
        this.deployDeployment();
      } else {
        // PROPORTION is set to ensure that not all page refreshes contact Maru servers and
        // this request wasn't picked - log this fact and we're done
        EDRSurveyCode.Tester.log(44, this.logId, [this.PROPORTION]);
      }
    },

    /**
     * Perform regular (every 500ms) functionality.
     * @private
     */
    _tick: function () {
      // set the ecos.dt cookie to the current date/time stamp
      EDRUtility.createCookie('ecos.dt', +new Date(), null, this.DOMAIN);
    },

    _attachDocumentEvents: function (doc) {
      var that = this;
      EDRUtility.addEvents(
        doc,
        ['click', 'mouseover', 'mouseout', 'keyup'],
        function (e) {
          for (var id in that.layers) {
            if (that.layers.hasOwnProperty(id)) {
              that.layers[id].onEvent(e);
            }
          }
        }
      );

      // must add focus and blur separately as they require event "capture" to be visible at a document level
      EDRUtility.addEvents(
        doc,
        ['focus', 'blur'],
        function (e) {
          for (var id in that.layers) {
            if (that.layers.hasOwnProperty(id)) {
              that.layers[id].onEvent(e);
            }
          }
        },
        true /* true = use capture */
      );
    },

    /**
     * Event from the layer - fired after the iframe is created.
     * @param {String}  id     The id of the layer.
     * @param {EDRSurveyCode.Layer}   layer  The layer object instance.
     */
    onLayerInitialised: function (id, layer) {
      this.layers[id] = layer;
    },

    /**
     * Event from the layer - fired after the layer has been destroyed.
     * @param {String}  id  The layer id.
     */
    onLayerDestroyed: function (id) {
      delete this.layers[id];
    },

    /**
     * Deploy the layer with the given id.
     *
     * Todo: Tidy the way these are done. We need to append 'l:' to the id to specify layer id. Ideally we just
     * want to use another parameter instead of using 'lid=xxx' for both deployments and layers.
     *
     * @param {string} id
     * @return {EDRSurveyCode.Layer}
     */
    deployLayer: function (id) {
      EDRSurveyCode.Tester.log(36, this.logId);
      var layerId = 'l:' + id;
      var l = this.layers[layerId];
      if (!l) {
        l = new EDRSurveyCode.Layer(layerId);
      }
      return l;
    },

    /**
     * Deploy the deployment with the given id.
     *
     * @param {string} deploymentId
     * @param {string} rootDeploymentId
     * @param {string} rootLanguage
     *
     * @return {EDRSurveyCode.Layer}
     */
    deployDeployment: function (deploymentId, rootDeploymentId, rootLanguage) {
      var l = this.layers[deploymentId];
      if (!l) {
        var cookieFC = EDRUtility.readCookie(
          EDRSurveyCode.Main.COOKIE_FORCED_CONFIG_PREFIX + deploymentId
        );

        if (deploymentId && !rootDeploymentId && cookieFC) {
          // If this isn't an initial layer request (deploymentId !== undefined) and a the layerConfig
          // wasn't explicitly provided, try to retrieve it from a forced configuration cookie.
          // This is needed in either of these cases:
          //   1) This method is called externally (from public interface) and caller doesn't provide rootDeploymentId.
          //   2) Forced deployments are required but the initial layer's l.php request also matched a trigger point.
          //      In this case l.php must respond to the trigger point matched layer first, and therefore the forced
          //      layers are deployed via deployAdditionalDeployments(). When this happens rootDeploymentId is
          //      not part of the l.php response (because it's not relevant to the triggerpoint-ed layer) and
          //      so the cookie is the only place we can get this ID.
          if (cookieFC['rdi'] !== '1') {
            rootDeploymentId = cookieFC['rdi'];
          }
        }

        if (deploymentId && !rootLanguage && cookieFC) {
          if (cookieFC['rl'] !== '') {
            rootLanguage = cookieFC['rl'];
          }
        }

        l = new EDRSurveyCode.Layer(
          deploymentId,
          rootDeploymentId,
          rootLanguage
        );
      }
      return l;
    },

    /**
     * Try to re-deploy the initial layer.
     *
     * This should be called if the URL has changed, such as through the use of history.pushState() or due to a
     * window.onhashchange event.
     *
     * @return {?EDRSurveyCode.Layer}
     */
    redeploy: function () {
      // don't try to redeploy the layer if the survey code hasn't finished loading
      if (!this._initialised) {
        return null;
      }

      var l = this.layers[null];

      // remove initial layer if it exists
      if (l) {
        l.destroy();
      }

      if (Math.random() < this.PROPORTION) {
        // This redeploy call passed the proportion check - try to deploy layer to the new URL
        return this.deployDeployment();
      }

      // PROPORTION is set to ensure that not all page refreshes contact Maru servers and
      // this redeploy call wasn't picked - log this fact and we're done
      EDRSurveyCode.Tester.log(44, this.logId, [this.PROPORTION]);

      return null;
    },

    /**
     * Returns the EDRSurveyCode.Layer instance with the specified name.
     * @param {String}  name  The layer name.
     * @return {EDRSurveyCode.Layer}
     */
    getLayerByName: function (name) {
      for (var id in this.layers) {
        if (this.layers.hasOwnProperty(id)) {
          if (this.layers[id].name === name) {
            return this.layers[id];
          }
        }
      }

      return null;
    },

    /**
     * Returns the EDRSurveyCode.Layer instance with the specified deployment id.
     * @param {String}  did  The deployment id.
     * @return {EDRSurveyCode.Layer}
     */
    getLayerByDeploymentId: function (did) {
      for (var id in this.layers) {
        if (this.layers.hasOwnProperty(id)) {
          if (this.layers[id].getDeploymentId() == did) {
            return this.layers[id];
          }
        }
      }
      return null;
    },

    /**
     * Returns the EDRSurveyCode.Layer instance with the specified name.
     * @param {String}  id  The layer id.
     * @return {EDRSurveyCode.Layer}
     */
    getLayerById: function (id) {
      if (this.layers[id]) {
        return this.layers[id];
      }
      return null;
    },

    buildUrl: function (destpage, query) {
      var url = this.eDRUrlBase + destpage + '.php?id=' + this.ID;

      if (!this._platformSupported) {
        url = url + '&s=0';
      }

      if (EDRSurveyCode.Tester.isActive()) {
        url = url + '&t=' + (EDRSurveyCode.Tester.receivedStrings ? 2 : 1);
      }

      url += '&v=' + this.VERSION; // + '&r=' + Math.round(Math.random() * 100000);

      var myvar2 = '',
        myvar = window.location.href;

      var icount = 0;
      for (var i = 0; i < myvar.length; i++) {
        if (myvar.charAt(i) === '/') {
          icount++;
        }
        if (icount >= 3) {
          myvar2 += myvar.charAt(i);
        }
      }

      if (this.LOCALE.length > 0) {
        url += '&l=' + encodeURIComponent(this.LOCALE);
      }

      if (this.DATA1.length > 0) {
        url += '&d1=' + encodeURIComponent(this.DATA1);
      }
      if (this.DATA2.length > 0) {
        url += '&d2=' + encodeURIComponent(this.DATA2);
      }
      if (this.DATA3.length > 0) {
        url += '&d3=' + encodeURIComponent(this.DATA3);
      }

      if (screen.width > 0 && screen.height > 0) {
        url += '&x=' + screen.width + '&y=' + screen.height;
      }

      if (screen.colorDepth > 0) {
        url += '&d=' + screen.colorDepth;
      }

      if (this._cookiesEnabled) {
        url +=
          '&c=' +
          encodeURIComponent(EDRUtility.readCookie(EDRSurvey.COOKIE_NAME, '.'));
      }

      url += '&ck=' + (this._cookiesEnabled ? '1' : '0');
      if (this._flashVersion !== null) {
        url += '&fl=' + encodeURIComponent(this._flashVersion);
      }

      if (this.PROPORTION < 1.0) {
        url += '&pr=' + this.PROPORTION;
      }
      url += '&p=' + encodeURIComponent(myvar2.substring(0, 100));
      if (document.referrer) {
        url +=
          '&ref=' + encodeURIComponent(document.referrer.substring(0, 100));
      }

      if (navigatorUserAgent.indexOf('Safari') >= 0) {
        url += '&fu=' + encodeURIComponent(window.location.href);
      }

      // Add the forced deployment cookies (if present) to the URL parameters
      var fdPrefix = EDRSurveyCode.Main.COOKIE_FORCED_CONFIG_PREFIX;
      var fdCookies = EDRUtility.readCookiesWithPrefix(fdPrefix);
      var fd = [];
      var fc = [];
      for (var fdCookie in fdCookies) {
        if (fdCookies.hasOwnProperty(fdCookie)) {
          try {
            fc.push(JSON.parse(fdCookies[fdCookie]));
            fd.push(fdCookie.substr(fdPrefix.length));
          } catch (e) {
            // squelch any exceptions thrown by JSON.parse() and skip over this cookie
          }
        }
      }

      if (0 < fd.length) {
        url += '&fd=' + encodeURIComponent(fd.join());
      }

      // If there is a root deployment id set in the first forced deployment cookie, send this along.
      // We only need to do this for the *first* forced deployment cookie because that is the only one which will
      // be served directly from *this* request to l.php. If there are any others they will be served via their
      // own l.php requests (spawned via deployAdditionalDeployments()) and so will have their rootDeploymentId
      // set via constructor argument.
      if (0 < fc.length && fc[0]['rdi'] !== '1') {
        url += '&rdi=' + encodeURIComponent(fc[0]['rdi']);
      }

      if (0 < fc.length && fc[0]['rl'] !== '' && !query['rlang']) {
        url += '&rlang=' + encodeURIComponent(fc[0]['rl']);
      }

      for (var key in query) {
        if (query.hasOwnProperty(key)) {
          url += '&' + key + '=' + encodeURIComponent(query[key]);
        }
      }

      return url;
    },

    /**
     * Return true if the browser environment supports this survey code, false otherwise.
     * @param {Boolean}  cookiesEnabled  True if cookies are supported and switched on.
     * @param {String}   flashVersion    The flash version installed as a dot separated string.
     * @return {Boolean}
     * @private
     */
    _testIfPlatformSupported: function (cookiesEnabled, flashVersion) {
      if (!cookiesEnabled) {
        // cookies *must* be switched on!
        return false;
      }

      var has = function (v) {
        return typeof v !== 'undefined' && v !== null;
      };

      // check for selector engine
      var hasSelectorEngine =
        has(document.querySelector) && has(document.querySelectorAll);

      if (!hasSelectorEngine) {
        return false;
      }

      if (!('classList' in document.createElement('_'))) {
        // we require the ability to see the list of classes an element has
        return false;
      }

      // ... we have a selector engine - check for xdm support
      var hasPostMessage = has(window.postMessage);
      if (hasPostMessage) {
        // Have postMessage, just need a library which works with it - only eDRXDMClient is fine
        return has(window['eDRXDMClient']);
      }

      return false;
    },

    /**
     * Public method to return an array of all the layers currently deployed.
     * @public
     * @returns {Array.<EDRSurveyCode.Layer>}
     */
    getLayers: function () {
      var ret = [];
      for (var id in this.layers) {
        if (this.layers.hasOwnProperty(id)) {
          ret.push(this.layers[id]);
        }
      }

      return ret;
    },

    /**
     * Public method to return the tester.
     * @public
     * @returns {EDRSurveyCode.Tester}
     */
    getTester: function () {
      return EDRSurveyCode.Tester;
    },

    /**
     * Convenience method to set multiple push-type probe values in a single call.
     * @public
     * @param {Object.<String,(Boolean|String|Number)>}  values  The probe data to set.
     */
    setProbeValues: function (values) {
      for (var key in values) {
        if (values.hasOwnProperty(key)) {
          this.setProbeValue(key, values[key]);
        }
      }
    },

    /**
     * Sets the value of a single push-type probe to either a string, number of boolean value.
     * @param {String}                 key    The name of the push-type probe to set.
     * @param {Boolean|String|Number}  value  The value of the probe to set.
     */
    setProbeValue: function (key, value) {
      // support string, number and bool types
      if (
        value.substring ||
        value.toFixed ||
        value === true ||
        value === false
      ) {
        this.pushedProbeValues[key] = value.substring
          ? value.toString()
          : value;
      } else {
        throw 'Invalid probe value for key: ' + key;
      }
    },

    /**
     * Gets pushProbeValue.
     *
     * @param {String}  key    The name of the push-type probe to get.
     */
    getProbeValue: function (key) {
      return this.pushedProbeValues[key];
    },

    /**
     * Gets pushProbeValues.
     *
     * Needs to be preserved so we can call this from the holding page after minification.
     */
    getProbeValues: function () {
      return this.pushedProbeValues;
    },

    /**
     * Set the the "domain access document" to the specified path.
     * This defaults to window.location, and is only used under IE when the client website is setting document.domain.
     * @param {String}  documentPath  The path to set.
     */
    setDomainAccessDocumentPath: function (documentPath) {
      if (documentPath.substring) {
        this.holdingWindowAccessDocument = documentPath;
      } else {
        throw 'Path must be a string';
      }
    },

    flagLightboxDisplayed: function (layerId) {
      if (EDRUtility.arrayIndexOf(this._layersWithLightboxes, layerId) === -1) {
        this._layersWithLightboxes.push(layerId);
      }

      if (this._layersWithLightboxes.length) {
        // first lightbox shown - constrain focus
        this._constrainFocus();
      }
    },

    flagLightboxHidden: function (layerId) {
      var idx = EDRUtility.arrayIndexOf(this._layersWithLightboxes, layerId);
      if (idx !== -1) {
        this._layersWithLightboxes.splice(idx, 1);
      }

      if (!this._layersWithLightboxes.length) {
        // last lightbox hidden - unconstrain focus
        this._unconstrainFocus();
      }
    },

    _constrainFocus: function () {
      // try to ensure that only the layer can be tabbed to by adding tabindex -1 to all normally focusable elements
      // we'll restore these when the lightbox hides
      var focusable = document.querySelectorAll(
        'a, button, input, select, [tabindex], iframe'
      );
      for (var i = 0; i < focusable.length; ++i) {
        var classes = focusable[i].className
          ? focusable[i].className.split(' ')
          : [];

        if (
          focusable[i].getAttribute &&
          focusable[i].setAttribute &&
          EDRUtility.arrayIndexOf(
            // ensure we don't stop the ability to tab to the execute buttons
            classes,
            EDRSurveyCode.Main.GO_CLICK_CAPTURER_CLASS
          ) === -1 &&
          EDRUtility.arrayIndexOf(
            // and ensure we continue to allow the layers to be tabbed into
            classes,
            EDRSurveyCode.Main.LAYER_IFRAME_CLASS
          ) === -1
        ) {
          this._savedElementTabIndices.push({
            e: focusable[i],
            ti: focusable[i].getAttribute('tabindex'),
          });

          focusable[i].setAttribute('tabindex', -1);
        }
      }
    },

    _unconstrainFocus: function () {
      // restore tabindexes
      for (var i = 0; i < this._savedElementTabIndices.length; ++i) {
        var o = this._savedElementTabIndices[i];
        o['ti'] !== null
          ? o['e'].setAttribute('tabindex', o['ti'])
          : o['e'].removeAttribute('tabindex');
      }

      // don't leak memory
      this._savedElementTabIndices = [];
    },
  };

  // Add an listener for the LAYER SHOWN event.
  EDRSurveyCode.EventHub.attach(
    EDRSurveyCode.EventHub.LAYER_SHOWN,
    function (arg) {
      if (
        null === EDRSurveyCode._getId() &&
        EDRSurveyCode.TRACKING_DAYS_DISABLED !== EDRSurvey.TRACKING_DAYS
      ) {
        EDRSurveyCode._generateAndSetId(EDRSurvey.TRACKING_DAYS);
      }
    }
  );

  // export the symbols that we want to be available externally, free from obfuscation by the closure compiler
  // this is essentially our public interface
  global['EDRSurvey'] = EDRSurveyCode.Main;
  EDRSurveyCode.Main['main'] = EDRSurveyCode.Main.main;
  EDRSurveyCode.Main['setProbeValue'] = EDRSurveyCode.Main.setProbeValue;
  EDRSurveyCode.Main['setProbeValues'] = EDRSurveyCode.Main.setProbeValues;
  EDRSurveyCode.Main['getProbeValue'] = EDRSurveyCode.Main.getProbeValue;
  EDRSurveyCode.Main['getProbeValues'] = EDRSurveyCode.Main.getProbeValues;
  EDRSurveyCode.Main['setDomainAccessDocumentPath'] =
    EDRSurveyCode.Main.setDomainAccessDocumentPath;
  EDRSurveyCode.Main['deployLayer'] = EDRSurveyCode.Main.deployLayer;
  EDRSurveyCode.Main['getLayers'] = EDRSurveyCode.Main.getLayers;
  EDRSurveyCode.Main['getTester'] = EDRSurveyCode.Main.getTester;
  EDRSurveyCode.Main['redeploy'] = EDRSurveyCode.Main.redeploy;

  EDRSurveyCode.Main['Events'] = EDRSurveyCode.EventHub;
  EDRSurveyCode.Main['Events']['attach'] = EDRSurveyCode.EventHub.attach;
  EDRSurveyCode.Main['Events']['detach'] = EDRSurveyCode.EventHub.detach;

  EDRSurveyCode.Layer.prototype['getDeploymentId'] =
    EDRSurveyCode.Layer.prototype.getDeploymentId;
  EDRSurveyCode.Layer.prototype['getName'] =
    EDRSurveyCode.Layer.prototype.getName;
  EDRSurveyCode.Layer.prototype['hide'] = EDRSurveyCode.Layer.prototype.hide;
  EDRSurveyCode.Layer.prototype['trigger'] =
    EDRSurveyCode.Layer.prototype.trigger;
  EDRSurveyCode.Layer.prototype['destroy'] =
    EDRSurveyCode.Layer.prototype.destroy;
  EDRSurveyCode.Layer.prototype['showLightbox'] =
    EDRSurveyCode.Layer.prototype.showLightbox;
  EDRSurveyCode.Layer.prototype['hideLightbox'] =
    EDRSurveyCode.Layer.prototype.hideLightbox;
  EDRSurveyCode.Layer.prototype['getDeployment'] =
    EDRSurveyCode.Layer.prototype.getDeployment;

  EDRSurveyCode.Deployment.prototype['getSurveyId'] =
    EDRSurveyCode.Deployment.prototype.getSurveyId;
  EDRSurveyCode.Deployment.prototype['getInstallationId'] =
    EDRSurveyCode.Deployment.prototype.getInstallationId;
  EDRSurveyCode.Deployment.prototype['getLayerId'] =
    EDRSurveyCode.Deployment.prototype.getLayerId;
  EDRSurveyCode.Deployment.prototype['getDeploymentId'] =
    EDRSurveyCode.Deployment.prototype.getDeploymentId;
  EDRSurveyCode.Deployment.prototype['getDeployId'] =
    EDRSurveyCode.Deployment.prototype.getDeployId;
  EDRSurveyCode.Deployment.prototype['getTriggerId'] =
    EDRSurveyCode.Deployment.prototype.getTriggerId;
  EDRSurveyCode.Deployment.prototype['getType'] =
    EDRSurveyCode.Deployment.prototype.getType;

  EDRSurveyCode.Main['getId'] = EDRSurveyCode.getId;

  /**
   * @expose
   */
  EDRSurvey.REMOTE_HOST = 'edigitalsurvey.com';
  /**
   * @expose
   */
  EDRSurvey.REMOTE_PATH = '/';
  /**
   * @expose
   */
  EDRSurvey.COOKIE_NAME = 'ckns_eds';
  /**
   * @expose
   */
  EDRSurvey.ID = 'INS-642345567';
  /**
   * @expose
   */
  EDRSurvey.LOCALE = '';
  /**
   * @expose
   */
  EDRSurvey.DATA1 = '';
  /**
   * @expose
   */
  EDRSurvey.DATA2 = '';
  /**
   * @expose
   */
  EDRSurvey.DATA3 = '';
  /**
   * @expose
   */
  EDRSurvey.TEST = false;
  /**
   * @expose
   */
  EDRSurvey.VERSION = '7291';
  /**
   * @expose
   */
  EDRSurvey.PROPORTION = 0.25;
  /**
   * @expose
   */
  EDRSurvey.DOMAIN = '';
  /**
   * @expose
   */
  EDRSurvey.TRACKING_DAYS = -1;

  // run the eDigitalResearch survey code
  EDRSurvey.main();
})(
  window,
  document,
  location,
  window.setTimeout,
  window.clearTimeout,
  window.setInterval,
  window.clearInterval,
  decodeURIComponent,
  encodeURIComponent,
  navigator.userAgent
);

(function () {
  EDRSurvey.bbc = {
    hasTaken: null,
  };

  EDRSurvey.Events.attach('layerShown', function (args) {
    EDRSurvey.bbc.hasTaken = false;
  });
})();
